import {Button, Col, Form, Input, InputNumber, message, Row, Select} from "antd";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

export const PTaskEdit = ({iv, adder, rend}) => {
    let params = useParams();
    let [vexa, setVexa] = useState([]);

    useEffect(() => {
        axios.get('/taskgroups/' + params.id).then(data =>  setVexa(data.data))
    }, []);
    const onFinish = (values) => {
        axios.post('/tasksedit/' + values.id, values).then(resData => {
            let result = resData.data
            console.log(result)
            message.success(`Ma'lumot muvaffaqiyatli yangilandi`);
            adder(!rend)
        })
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const [form] = Form.useForm()
    useEffect(() => {
        form.setFieldsValue(iv)

    }, [form, iv])

    return (
        <>
            <Form
                form={form}
                name={'editform'}
                initialValues={iv}
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <fieldset>
                    <legend>Main Data</legend>
                    <Form.Item hidden={true} name="id">
                        <InputNumber/>
                    </Form.Item>
                    <Form.Item label="Название" name="name">
                        <Input placeholder={'Name'}/>
                    </Form.Item>
                    <Row gutter={15}>
                        <Col span={12}>
                            <Form.Item label="Начало" name="start">
                                <Input type={"date"}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Окончание" name="end">
                                <Input type={"date"}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item label="Группа задач" name='p_task_group_id'>
                        <Select>
                            {vexa?.map(item => <Select.Option key={item.id}
                                                              value={item.id}>{item.name}</Select.Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Очеред" name="displayOrder">
                        <InputNumber placeholder={'displayOrder'}/>
                    </Form.Item>
                    <Form.Item label="Информация" name="description">
                        <TextArea placeholder={'Description'}/>
                    </Form.Item>
                </fieldset>
                <hr/>
                <Form.Item>
                    <Button type={"primary"} size={'large'} htmlType="submit">Обновить</Button>
                </Form.Item>
            </Form>
        </>
    )
}