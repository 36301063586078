import { Badge, Descriptions, Tabs } from 'antd';
import React from 'react';
import EmData from './ECard/EmData';
import EmPayment from './ECard/EmPayment';

function EmployeeCard({ user }) {

    const onChange = (key) => {
        console.log(key);
    };
    return (
        <div style={{ height: '800px', overflow: 'auto' }}>
            <Tabs
                onChange={onChange}
                type="card"
                items={[
                    {
                        key: 1,
                        label: `Данные сотрудника`,
                        children: <EmData user={user} />,
                    },
                    {
                        key: 2,
                        label: `Заработная плата сотрудника`,
                        children: <EmPayment user={user} />,
                    },
                ]}
            />
        </div>
    );
}

export default EmployeeCard;