import {GanttComponent} from "../../../Components/Gantt/GanttComponent";
import {useEffect, useState} from "react";
import axios from "axios";
import {useParams} from "react-router-dom";

export function TaskGant() {
    let params = useParams();
    let [task, setTask] = useState([
        {
            start: new Date(),
            end: new Date(),
            name: "Test",
            id: "1",
            progress: 50,
            project: 'Testcha',
            type: "project",
            hideChildren: true,
            displayOrder: 1,
            styles: {progressColor: 'blue', progressSelectedColor: 'aquamarine', barProgressColor: 'aqua'},
        },
    ])
    let [rend, setRend] = useState(false);

    useEffect
    (() => {
        axios.get('/tasksgant/' + params.id).then(res => {
            let gantdata = res.data.map((item, index) => {
                item.start = new Date(item.start)
                item.end = new Date(item.end)
                item.project = item.project_name
                // item.dependencies = [String(item.task_group_id)]
                item.dependencies = []
                item.displayOrder = item.id
                item.progress = isNaN(Number(item.progress)) ? 0 : Number(item.progress)
                return item;
            })
            setTask(gantdata)
            // console.log(gantdata)
        })
    }, [rend]);

    return (
        <>
            <GanttComponent data={task}/>
        </>
    )
}