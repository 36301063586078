import {Link, Route, Routes} from "react-router-dom";
import {SProj} from "./SProj";
import {useEffect, useState} from "react";
import logo from '../../assets/mayaknewlogo.png'
import {Avatar, Button, Card, Col, Progress, Row, Space} from "antd";
import {DeleteOutlined, EditOutlined, EyeOutlined, PlusOutlined} from "@ant-design/icons";
import Meta from "antd/es/card/Meta";
import {useProject} from "./ProjectState";
import {Drawer} from "antd/lib";
import {ProjectAdd} from "./ProjectAdd";

export function Projects() {
    let {projects, setProjects} = useProject()
    let [open, setOpen] = useState(false)

    useEffect(() => {
        setProjects()
    }, []);
    return (
        <>
            <Routes>
                <Route path={'/'} element={(
                    <>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            <h3>Проекты</h3>
                            <Button icon={<PlusOutlined/>} shape={'circle'} type={'primary'}
                                    onClick={() => setOpen(true)}
                            />
                        </div>
                        <Drawer onClose={() => setOpen(false)} open={open} title={"Добавить проект"} width={700}>
                            <ProjectAdd/>
                        </Drawer>
                        <Row gutter={15}>
                            {projects.map(project => (
                                <Col span={6} key={project.id}>
                                    <PRCard card={project}/>
                                </Col>
                            ))}
                        </Row>
                    </>
                )}/>
                <Route path={'/:id'} element={<SProj/>}/>
            </Routes>
        </>
    )
}

function PRCard({card}) {
    let {onDelete} = useProject()
    return <>
        <Card
            key={card.id}
            title={card.project_name}
            actions={[
                <Space>
                    <Link to={'/main/service/' + card.id}>
                        <Button
                            value={card.id}
                            icon={<EyeOutlined/>}
                            type={'primary'}
                            style={{backgroundColor: 'green'}}>
                            Подробнее...
                        </Button>

                    </Link>
                    <Button
                        value={card.id}
                        icon={<EditOutlined/>}
                        type={'primary'}/>
                    <Button
                        icon={<DeleteOutlined/>}
                        danger
                        type={'primary'}
                        onClick={() => onDelete(card.id)}
                    />
                </Space>
            ]}
        >
            <Meta
                avatar={<Avatar src={logo}/>}
                // title={card.metadata.project_budget ?? '0'}
                description={'sum'}
            />
            <Progress percent={card.completion_percent}
                      status={(new Date() < new Date(card.deadline)) ? "active" : "exception"}/>
            <p><b>Начало: </b> {card.start_date}</p>
            <p><b>Срок: </b> {card.deadline}</p>
            <p>{card.description}</p>
        </Card>
    </>
}

