import {Button, Col, Form, Input, InputNumber, message, Row} from "antd";
import {useEffect} from "react";
import axios from "axios";
import {useParams} from "react-router-dom";

export function PVexaEdit({iv, setRend, rend}) {
    let params = useParams();
    const onFinish = (values) => {
        values.project_id = params.id;
        axios.put('/taskgroups/' + values.id, values).then(res => {
            console.log(res)
            message.success(`Ma'lumot muvaffaqiyatli yangilandi`);
            setRend(!rend)
        })

    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const [form] = Form.useForm()
    useEffect(() => {
        form.setFieldsValue(iv)
    }, [form, iv])


    return (
        <>
            <Form
                form={form}
                name={'editform'}
                initialValues={iv}
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item hidden={true} name="id">
                    <InputNumber/>
                </Form.Item>
                <Form.Item label="Название" name="name">
                    <Input placeholder={'Name'}/>
                </Form.Item>
                <Row gutter={15}>
                    <Col span={12}>
                        <Form.Item label="Начало" name="start">
                            <Input type={"date"}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Окончание" name="end">
                            <Input type={"date"}/>
                        </Form.Item>
                    </Col>

                </Row>
                <hr/>
                <Form.Item>
                    <Button type={"primary"} size={'large'} htmlType="submit">Обновить</Button>
                </Form.Item>
            </Form>
        </>
    )
}