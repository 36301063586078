import { Col, Row } from 'antd';
import React from 'react';
import { LeadEdit } from './LeadEdit';

function LeadCard({rend, setRend, ed, stdata}) {
    return (
        <Row gutter={15}>
            <Col span={14} style={{ boxShadow: 'inset 0px 0px 10px -3px gray', borderRadius: '15px', overflow: 'auto' }}></Col>
            <Col span={10} style={{ padding: '15px', overflow: 'auto', height: '89vh' }}>
                <LeadEdit rend={rend} setRend={setRend} ed={ed} stdata={stdata} />
            </Col>
        </Row>
    );
}

export default LeadCard;