import { Avatar, Button, Card, Divider, Form, Modal, Progress, Tooltip } from "antd";
import { CheckCircleOutlined, EyeOutlined } from "@ant-design/icons";
import Meta from "antd/es/card/Meta";
import logo from "../../../assets/mayaknewlogo.png";
import { useTasks } from "./TasksState";
import { useState } from "react";
import axios from "axios";
import TextArea from "antd/es/input/TextArea";

export const BoardCard = ({ card, setModal }) => {
    let [checkModal, setCheckModal] = useState(false)
    let [rend, setRend] = useState(false)
    let stdata = new Date(card?.start)
    let enddata = new Date(card?.end)
    let { setMetaData } = useTasks()
       
    const addCommenttoMicroTask = (rec) => {
        axios.put('taskdetailcheck/' + card.id, { pid: card.project_id, ...rec }).then(res => {
            if (res.status === 200) {
                setRend(!rend)
                setCheckModal(false)
                // window.location.reload();
            }
        })
    }
    return (
        <>
            <Modal onCancel={() => setCheckModal(false)} open={checkModal} title="Введите информацию о выполнение задач..." footer={false}>
                <Form
                    labelCol={{
                        span: 24,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    onFinish={addCommenttoMicroTask}>
                    <Form.Item label="Ввведите описание для выполненных задач" name={"exe_comment"} rules={[{ required: true, message: "Заполните" }]}>
                        <TextArea />
                    </Form.Item>

                    <Form.Item>
                        <Button htmlType="submit" type="primary">Потдвердить</Button>
                    </Form.Item>
                </Form>
            </Modal>
            <Card
                key={card.id}
                title={card?.name ?? ''}
                style={{ width: '300px', marginBottom: '10px' }}
                actions={[
                    <Button
                        icon={<CheckCircleOutlined />}
                        type={'primary'}
                        onClick={() => {
                            setCheckModal(true)
                            // setMetaData(card)
                            
                        }}
                    >
                        Выполнено
                    </Button>
                    ,
                    <Button
                        icon={<EyeOutlined />}
                        type={'primary'}
                        style={{ backgroundColor: 'green' }}
                        onClick={() => {
                            setModal(true)
                            setMetaData(card)
                        }}
                    >
                        Подробнее...
                    </Button>
                ]}
            >
                <Meta
                    avatar={<Avatar src={logo} />}
                    title={`${stdata.getDate()}/${stdata.getMonth() + 1}/${stdata.getFullYear()}  - ${enddata.getDate()}/${enddata.getMonth() + 1}/${enddata.getFullYear()}`}
                    description={<Progress percent={card.progress ?? 0}
                        status={(new Date() < new Date(card.metadata?.deadline)) ? "active" : "exception"} />}
                />
                <h4>Проект: {card.project_name}</h4>
                <h4>Группа задач: {card.taskgroupname}</h4>
                <Divider />
                <h4>Испольнители</h4>
                <Avatar.Group>
                    {card?.users?.map(u => <Tooltip key={'id'} title={u.firstname + ' ' + u.lastname}
                        placement="top">
                        <Avatar style={{ border: '1px solid silver' }} alt={u.firstname} src={u.img} />
                    </Tooltip>)}
                </Avatar.Group>
            </Card>
        </>
    )
}