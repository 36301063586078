import { message } from "antd";
import axios from "axios";
import { render } from "katex";
import { create } from "zustand";

export const useCats = create((set, get) => ({
    cats: [],
    ed: {},
    setEd: (ed) => {
        set({ ed: ed });
    },
    columns: [
        { title: 'Название', dataIndex: 'name', key: 'name' },
        { title: 'Цвет', width: 200, dataIndex: 'color', key: 'color', render: (color) => <div style={{ width: '20px', height: '20px', backgroundColor: color }}></div> },
    ],
    getCats: () => {
        axios.get('sertypes')
            .then((response) => {
                set({ cats: response.data });
            })
            .catch((error) => {
                message.error(error.message);
            });
    },
    addCat: (cat) => {
        axios.post('sertypes', cat)
            .then((response) => {
                if (response.status === 200) {
                    get().getCats();
                }
            })
            .catch((error) => {
                message.error(error.message);
            }
            );
    },
    updateCat: (cat) =>{
        axios.put('sertypes/' + cat.id, cat)
            .then((response) => {
                if (response.status === 200) {
                    get().getCats();
                }
            })
            .catch((error) => {
                message.error(error.message);
            }
            );
    },
    deleteCat: (id) => {
        axios.delete('sertypes/' + id.id)
            .then((response) => {
                if (response.status === 200) {
                    get().getCats();
                }
            })
            .catch((error) => {
                message.error(error.message);
            }
            );
    }

}));