import { MainTable } from "../../Components/MainTable/MainTable";

export function PAllTasks() {
    return (
        <>
            <MainTable
                pageTitle={'Мои Задачи'}
            />
        </>
    )
}