import { Button, Form, Input } from 'antd';
import React from 'react';
import { useCats } from './CatsStore';

function Add(props) {

    let {addCat} = useCats();

    return (
        <div>
            <Form
                name="basic"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                onFinish={(v)=>{
                    console.log(v);
                    addCat(v);
                }}
            >
                <Form.Item
                    label="Название категории"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: 'Введите название категории!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Цвет"
                    name="color"
                >
                    <Input type='color' />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Добавить
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default Add;