import { create } from "zustand";
import axios from "axios";
import { message } from "antd/lib";

export let useTasks = create((set, get) => ({
    data: { data: [], ls: [] },
    modal: false,
    setModal: (type) => set({ modal: type }),
    metaData: {},
    boardData: {
        lanes: [{
            id: '1', title: '_', label: '0',
            cards: [
                { id: '1', title: 'Yuklanmoqda', description: '---', label: '0 min', draggable: true },
            ]
        }]
    },
    open: false,
    setData: (params) => {

        let user = JSON.parse(localStorage.getItem('Bearer_state'))
        axios.get('/taskboard/' + params.id).then(data => {

            if ((user.role_id === 1) || (user.role_id === 2)) {
                let bD = data.data.data.map(it => {
                    it.id = String(it.id)
                    it.title = it.name
                    // it.metadata = it
                    return it
                })
                let source = data.data.ls.map(item => {
                    item.cards = bD.filter(i => i.ptask_status_id === item.id) ?? []
                    item.title = item.name
                    item.label = String(item.cards.length)
                    item.id = String(item.id)
                    return item
                })
                set({ boardData: { lanes: source }, data: data.data })
            } else {
                let bD = data.data.data.filter(f => {
                    let exex = JSON.parse(f.execut)
                    let r = exex.find(s => s === user.empid)
                    return (r) ? true : false
                }).map(it => {
                    it.id = String(it.id)
                    it.title = it.name
                    // it.metadata = it
                    return it
                })
                let source = data.data.ls.map(item => {
                    item.cards = bD.filter(i => i.ptask_status_id === item.id) ?? []
                    item.title = item.name
                    item.label = String(item.cards.length)
                    item.id = String(item.id)
                    return item
                })
                set({ boardData: { lanes: source }, data: data.data })
            }

        })
    },
    setMetaData: (data) => set({ metaData: data }),
    onCardMoveAcrossLanes: (fromLaneId, toLaneId, cardId, index, rend, setRend) => {
        let values = { status_id: toLaneId };
        axios.post('/taskstupd/' + cardId, values).then(resData => {
            if (resData.status === 200) {
                message.success('Изменение выполнено!')
                setRend(!rend)
            }
        })
    }
}))