import { useEffect, useState} from "react";
import axios from "axios";
import {useParams} from "react-router-dom";
import {FileCard} from "../../../Components/FileCard/FileCard";
import {Button, Checkbox} from "antd";

export const PFiles = () => {
    let [files, setFiles] = useState([])
    let [rend, setRend] = useState(false)
    let [zips, setZips] = useState([])
    let p = useParams()

    const [images, setImages] = useState([])

    useEffect(() => {
        axios.get('taskfiles/' + p.id).then(res => {
            if (res.status === 200) {
                setFiles(res.data)
            }
        })
    }, [rend]);

    let onDelete = (id) => {
        axios.delete('taskfiles/' + id).then(res => {
            if (res.status === 200) {
                setRend(!rend)
            }
        })
    }
    axios.defaults.mode = 'no-cors'
    axios.defaults.headers.post['Content-Type'] = 'application/json, *'

    let onChange = (e, f) => {
        let filepath = f.filepath.slice(1)
        if (e.target.checked) {
            setImages([filepath, ...images])
        } else {
            setImages(images.filter(im => im !== filepath))
        }
    };
    // ZIP

    // const { handleZip } = useDownload();

    let onZip = () => {
        axios.post('createzip', {
            files: images,
            pid: p.id
        }).then(res => {
            console.log(res)
            setRend(!rend)
        })
    }

    useEffect(() => {
        axios.get('getzip/' + p.id).then(res => {
            if (res.status === 200) {
                setZips(res.data)
            }
        })
    }, [rend]);
    return (
        <>
            <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '25px'}}>
                <h4>Все файлы проекта</h4>
                <Button type={'primary'} onClick={onZip}>Все выделенные файлы сжать на ZIP</Button>
                <Button type={'primary'} disabled={(zips.length > 0) ? false : true}>
                    {(zips.length > 0)
                        ? <a href={process.env.REACT_APP_MAINURL + '/' + zips[0]?.url}>Скачать последный ZIP
                            файл</a>
                        : "zip архив еше не сформировано"}


                </Button>
            </div>
            <div style={{display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '15px'}}>
                {files.map(f => <div key={f.id}>
                    <Checkbox onChange={(e) => onChange(e, f)}>Выбрать</Checkbox>
                    <FileCard file={f} onDelete={() => onDelete(f.id)}/>
                </div>)}
            </div>
        </>
    )
}