import React from 'react';

function Add(props) {
    return (
        <div>
            Nimadir qo'shish
        </div>
    );
}

export default Add;