import {useAuthHeader} from "react-auth-kit";
import {
    PoweroffOutlined,
    CalendarOutlined,
    BarChartOutlined,
    ApiOutlined,
    BoxPlotOutlined,
    CarryOutOutlined,
    IdcardOutlined,
    RightCircleOutlined,
    LeftCircleOutlined,
    ExpandOutlined,
    CompressOutlined,
    SettingOutlined,

} from '@ant-design/icons';
import {Layout, Menu, theme, Button, Space, message, Spin} from 'antd';
import React, {useState} from 'react';
import {NavLink, Route, Routes, useHref} from "react-router-dom";
import {Leads} from "../../Pages/Leads/Leads";
import {Employee} from "../../Pages/Employee/Employee";
import logo from './../../assets/mayaknewlogo.png'
import {Valkurs} from "./../MainLayout/Valkurs";
import {Notes} from "./../MainLayout/Notes";
import {Notific} from "./../MainLayout/Notific";
import {Projects} from "../../Pages/Project/Projects";
import {PAllTasks} from "../../Pages/Project/PAllTasks";
import {Events} from "../../Pages/Events/Events";
import axios from "axios";
import {Dashboard} from "../../Pages/Dashboard/Dashboard";
import {FullScreen, useFullScreenHandle} from "react-full-screen";
import {Pstatus} from "../../Pages/Project/Pstatus/Pstatus";
import {ProjectAdd} from "../../Pages/Project/ProjectAdd";
import {ServiceTypes} from "../../Pages/ServiceTypes/ServiceTypes";
import {SProj} from "../../Pages/Project/SProj";
import {Action} from "../../Components/Action";

const {Header, Sider, Content} = Layout;
export const ManufactoryLayout = ({signOut, ws}) => {

    let [loader, setLoader] = useState(true)
    const authHeader = useAuthHeader()
    const handle = useFullScreenHandle();
    axios.defaults.baseURL = process.env.REACT_APP_URL;
    axios.defaults.headers.common['Authorization'] = authHeader();
    axios.defaults.headers.post['Content-Type'] = 'application/json';

    let activeLink = useHref()

    axios.interceptors.request.use(
        request => {
            return request;
        },
        err => {
            throw err;
        },
    );
    axios.interceptors.response.use(response => {
        if (response.status === 200) {
            setLoader(false)
        }
        return response;
    }, error => {
        if (error === null) {
            message.error("Bunday Ma'lumotlar topilmadi")
        } else if (error.response.status === 401) {
            signOut()
        }
        return error;
    });

    const [collapsed, setCollapsed] = useState(false);
    const {
        token: {colorBgContainer},
    } = theme.useToken();

    const fullSC = () => {
        (handle.active) ? handle.exit() : handle.enter()
    }

    return (
        <>
            <FullScreen handle={handle}>
                <Spin spinning={loader} style={{height: '100vh'}} tip="Loading...">
                    <Layout style={{height: '100vh'}}>
                        <Sider style={{backgroundColor: '#fff'}} width={250} trigger={null} collapsible
                               collapsed={collapsed}
                               collapsedWidth={0}>
                            <Menu
                                theme="light"
                                mode="inline"
                                defaultSelectedKeys={activeLink}
                                items={[
                                    {
                                        key: "/main",
                                        icon: <img width={'30px'} src={logo} alt="Mayak"/>,
                                        label: <NavLink to="/main">MAYAK MEDIA MARKET</NavLink>,
                                    },

                                    {
                                        key: "/main/",
                                        icon: <BoxPlotOutlined/>,
                                        label: <NavLink to="/main/">Проекты</NavLink>,
                                    },


                                    {
                                        key: "/main/events",
                                        icon: <CalendarOutlined/>,
                                        label: <NavLink to="/main/events">Личные данные</NavLink>,
                                    },
                                ]}
                            />
                        </Sider>
                        <Layout className="site-layout" style={{backgroundColor: 'rgb(240, 242, 245)'}}>
                            <Header
                                style={{
                                    padding: '0px 25px',
                                    height: '45px',
                                    background: colorBgContainer,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                {React.createElement(collapsed ? RightCircleOutlined : LeftCircleOutlined, {
                                    className: 'trigger',
                                    onClick: () => setCollapsed(!collapsed),
                                })}
                                <Space className="right">
                                    <Button onClick={fullSC} type={'primary'} shape={'circle'}
                                            icon={handle.active ? <CompressOutlined/> : <ExpandOutlined/>}
                                            size={'small'}/>
                                    {/* <Notific ws={ws}/> */}
                                    <Notes/>
                                    <Valkurs/>
                                    <Action/>
                                    <Button
                                        size={'small'}
                                        shape={'circle'}
                                        type="primary"
                                        icon={<PoweroffOutlined/>}
                                        onClick={signOut}
                                        danger
                                    />
                                </Space>
                            </Header>
                            <Content
                                style={{
                                    padding: 24,
                                    minHeight: 280,
                                    background: 'rgb(240, 242, 245)',
                                    maxHeight: '100%',
                                    overflow: "auto"
                                }}
                            >
                                <Routes>
                                    <Route path={'/'} element={<Projects/>}/>
                                    <Route path={'/service/:id'} element={<SProj/>}/>
                                    <Route path={'/events'} element={<Events/>}/>
                                </Routes>
                            </Content>
                        </Layout>
                    </Layout>
                </Spin>
            </FullScreen>
        </>
    )
}