import {create} from "zustand";
import axios from "axios";
import {message} from "antd/lib";
import {Image} from "antd";

export let useServiceTypes = create((set, get) => ({
    serviceTypes: [],
    edData: {},
    setEddata: (data) => set({edData: data}),
    columns: [
        {
            title: 'Картинка',
            dataIndex: 'img',
            key: 'img',
            width: 150,
            render: (_, rec) => <Image width={50} src={process.env.REACT_APP_MAINURL + rec.img}/>
        },
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
        },
    ],
    setService: () => {
        axios.get('sertypes').then(res => {
            set({serviceTypes: res.data})
        }).catch(err => message.error("Ma'lumotni yuklashda hatolik!!!"))
    },
    onAdd: (values) => {
        axios.post('sertypes', values).then((res) => {
            if (res.status === 200) {
                get().setService()
            }
        })
    },
    onDelete: (_, rec) => {
        axios.delete('sertypes/' + rec.id).then(rec => {
            get().setService()
        })
    },
    onEdit: (values) => {
        axios.put('sertypes/' + values.id, values).then(res => {
            get().setService()
        })
    },
    onFinishFailed: (error) => {
        console.log(error)
    }
}))