import { Button, Form, Input, Modal, Select, Space, Table, TimePicker } from "antd";
import { useEffect, useState } from "react";
import { CheckCircleOutlined, ClockCircleOutlined, InfoCircleOutlined, ReloadOutlined } from "@ant-design/icons";
import axios from "axios";

export function Muster() {
    let [month, setMonth] = useState(new Date().getMonth())
    let [year, setYear] = useState(new Date().getFullYear())
    let [rend, setRend] = useState(true)
    const [isModalOpen, setIsModalOpen] = useState(false);
    let [data, setData] = useState([])
    let [xodimData, setXodimData] = useState({})
    let [smenes, setSmenes] = useState([])
    let [checkTime, setCheckTime] = useState([])


    useEffect(() => {
        axios.get('/muster/' + month + '/' + year).then(data => {
            setData(data.data)
            console.log(data)
        })

    }, [month, year, rend]);

    useEffect(() => {
        axios.get('/smenes').then(data => {
            setSmenes(data.data)
        })

    }, [rend]);

    let months = [
        {
            value: '0',
            label: 'Январь'
        },
        {
            value: '1',
            label: 'Февраль'
        },
        {
            value: '2',
            label: 'Март'
        },
        {
            value: '3',
            label: 'Апрель'
        },
        {
            value: '4',
            label: 'Май'
        },
        {
            value: '5',
            label: 'Июнь'
        },
        {
            value: '6',
            label: 'Июль'
        },
        {
            value: '7',
            label: 'Август'
        },
        {
            value: '8',
            label: 'Сентябрь'
        },
        {
            value: '9',
            label: 'Октябрь'
        },
        {
            value: '10',
            label: 'Ноябрь'
        },
        {
            value: '11',
            label: 'Декабрь'
        },
    ]
    let c = [
        {
            title: 'Фамилья',
            dataIndex: 'lastname',
            key: 'lastname',
        },
        {
            title: 'Имя',
            dataIndex: 'firstname',
            key: 'firstname',
        },
        {
            title: 'Месяц',
            dataIndex: 'month',
            key: 'month',
            render: (_, rec) => {
                // console.log(rec)
                return <p>{months[rec.month].label}</p>
            }
        },
        {
            title: 'Год',
            dataIndex: 'year',
            key: 'year',
        },
        {
            title: 'Время за месяц',
            dataIndex: 'worktime',
            key: 'worktime',
        },
    ]

    const handleChange = (rec, day) => {
        // console.log(rec, day)
        rec.changedData = day
        setXodimData(rec)
        setIsModalOpen(true);
    }

    let d = new Date()
    let d2 = new Date()

    console.log(d.getMonth())

    for (let i = 0; i < new Date().daysInMonth(); i++) {
        let f = i + 1
        let newCol = {
            title: f,
            dataIndex: f,
            key: f,
            width: 20,
            render: (_, rec) => {
                return <Button title={rec[f]}
                    style={{ backgroundColor: (rec[f]) ? (rec[f] < 5) ? 'yellow' : 'lightgreen' : 'lightpink' }}
                    size={'small'}
                    icon={(rec[f]) ? (rec[f] < 5) ? <InfoCircleOutlined /> : <CheckCircleOutlined /> :
                        <ClockCircleOutlined />}
                    onClick={() => handleChange(rec, f)} />
            }
        }
        c.push(newCol)
    }

    const handleSelect = (e) => {
        let ym = e.target.value.split('-');
        let n = Number(ym[1] - 1)
        setMonth(String(n))
        setYear(String(ym[0]))
    };
    let currentMonth = months.find(item => item.value === String(month))

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleMGenerate = () => {
        axios.post('/generatemuster', { month: month, year: year }).then(res => setRend(!rend))
    }

    const timeOne = (e, rec) => {
        console.log(e, rec)
        setCheckTime(rec)
    }

    const onFinish = (rec) => {
        let soat = Number(rec.times[1]['$H']) - Number(rec.times[0]['$H'])
        let min = Number(rec.times[1]['$m']) - Number(rec.times[0]['$m'])

        let send = {};
        // `xodim_id`, `in_time`, `out_time`, `status`, `note`, `smena_id`
        send.xodim_id = xodimData.xodim_id;
        send.in_time = checkTime[0]
        send.out_time = checkTime[1]
        send.note = rec.note
        send.smena_id = rec.smena_id
        send.day = xodimData.changedData
        send.month = xodimData.month
        send.year = xodimData.year
        send.status = rec.status
        send.worktime = (soat * 60) + min
        console.log(send)

        axios.post('/worktimecheck', send).then(resData => {
            let result = resData.data
            setRend(!rend)
        })
    }
    return (
        <>
            <Modal footer={false} title="Посещаемость" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <Form labelCol={{
                    span: 10,
                }}
                    wrapperCol={{
                        span: 14,
                    }} onFinish={onFinish}>
                    <Form.Item label="Время входа и выхода" name="times">
                        <TimePicker.RangePicker minuteStep={5} format={'HH:mm'} onChange={timeOne} />
                    </Form.Item>
                    <Form.Item label='Выберите смену' name={'smena_id'}>
                        <Select>
                            {smenes?.map(item => <Select.Option key={item.id}
                                value={item.id}>{item.name}</Select.Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item label='Особый статус' name={'status'}>
                        <Select>
                            <Select.Option value={'Опоздал'}>Опоздал</Select.Option>
                            <Select.Option value={'Рано ушел'}>Рано ушел</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="Заметка если опоздал(а)" name="note">
                        <Input placeholder={'Izoh'} />
                    </Form.Item>
                    <Form.Item>
                        <Button type={"primary"} htmlType="submit">Отметить</Button>
                    </Form.Item>
                </Form>
            </Modal>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h1>Посешаемость за {months[month].label}</h1>
                <Space>
                    <Input style={{ width: '200px' }} onInput={handleSelect} type={'month'} />
                    <Button onClick={handleMGenerate} type={"primary"} icon={<ReloadOutlined />} />
                </Space>
            </div>
            <Table bordered scroll={{
                x: 240,
            }} rowKey={'id'} columns={c} dataSource={data} />
        </>
    )
}