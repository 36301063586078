import { BarChartOutlined, BoxPlotOutlined, CalendarOutlined, CarryOutOutlined, CompressOutlined, ExpandOutlined, LeftCircleOutlined, PoweroffOutlined, RightCircleOutlined } from '@ant-design/icons';
import { Button, Layout, Menu, message, Space, Spin, theme } from 'antd';
import Sider from 'antd/es/layout/Sider';
import axios from 'axios';
import React, { useState } from 'react';
import { useAuthHeader } from 'react-auth-kit';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { NavLink, Route, Routes, useHref } from 'react-router-dom';
import logo from './../../assets/mayaknewlogo.png'
import { Content, Header } from 'antd/es/layout/layout';
import { Notific } from '../MainLayout/Notific';
import { Notes } from '../MainLayout/Notes';
import { Valkurs } from '../MainLayout/Valkurs';
import { Action } from '../../Components/Action';
import { Dashboard } from '../../Pages/Dashboard/Dashboard';
import { Projects } from '../../Pages/Project/Projects';
import { SProj } from '../../Pages/Project/SProj';
import { ProjectAdd } from '../../Pages/Project/ProjectAdd';
import { Pstatus } from '../../Pages/Project/Pstatus/Pstatus';
import { PAllTasks } from '../../Pages/Project/PAllTasks';
import { Events } from '../../Pages/Events/Events';
import { ServiceTypes } from '../../Pages/ServiceTypes/ServiceTypes';
function Director({signOut, ws}) {


    let [loader, setLoader] = useState(true)
    const authHeader = useAuthHeader()
    const handle = useFullScreenHandle();
    axios.defaults.baseURL = process.env.REACT_APP_URL;
    axios.defaults.headers.common['Authorization'] = authHeader();
    axios.defaults.headers.post['Content-Type'] = 'application/json';

    let activeLink = useHref()

    axios.interceptors.request.use(
        request => {
            return request;
        },
        err => {
            throw err;
        },
    );
    axios.interceptors.response.use(response => {
        if (response.status === 200) {
            setLoader(false)
        }
        return response;
    }, error => {
        if (error === null) {
            message.error("Bunday Ma'lumotlar topilmadi")
        } else if (error.response.status === 401) {
            signOut()
        }
        return error;
    });

    const [collapsed, setCollapsed] = useState(false);
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const fullSC = () => {
        (handle.active) ? handle.exit() : handle.enter()
    }

    return (
        <>
            <FullScreen handle={handle}>
                <Spin spinning={loader} style={{ height: '100vh' }} tip="Loading...">
                    <Layout style={{ height: '100vh' }}>
                        <Sider style={{ backgroundColor: '#fff' }} width={250} trigger={null} collapsible
                            collapsed={collapsed}
                            collapsedWidth={0}>
                            <Menu
                                theme="light"
                                mode="inline"
                                defaultSelectedKeys={activeLink}
                                items={[
                                    {
                                        key: "/main",
                                        icon: <img width={'30px'} src={logo} alt="Mayak" />,
                                        label: <NavLink to="/main">MAYAK MEDIA MARKET</NavLink>,
                                    },
                                    {
                                        key: "/main/",
                                        icon: <BarChartOutlined />,
                                        label: <NavLink to="/main/">Показателы</NavLink>,
                                    },

                                    {
                                        key: '5',
                                        icon: <BoxPlotOutlined />,
                                        label: 'Проекты',
                                        children: [
                                            {
                                                key: "/main/services",
                                                icon: <BoxPlotOutlined />,
                                                label: <NavLink to="/main/services">Проекты</NavLink>,
                                            },
                                            {
                                                key: "/main/tasks",
                                                icon: <CarryOutOutlined />,
                                                label: <NavLink to="/main/tasks">Задачы</NavLink>,
                                            }
                                        ]
                                    },

                                    {
                                        key: "/main/events",
                                        icon: <CalendarOutlined />,
                                        label: <NavLink to="/main/events">Событии</NavLink>,
                                    },
                                ]}
                            />
                        </Sider>
                        <Layout className="site-layout" style={{ backgroundColor: 'rgb(240, 242, 245)' }}>
                            <Header
                                style={{
                                    padding: '0px 25px',
                                    height: '45px',
                                    background: colorBgContainer,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                {React.createElement(collapsed ? RightCircleOutlined : LeftCircleOutlined, {
                                    className: 'trigger',
                                    onClick: () => setCollapsed(!collapsed),
                                })}
                                <Space className="right">
                                    <Button onClick={fullSC} type={'primary'} shape={'circle'}
                                        icon={handle.active ? <CompressOutlined /> : <ExpandOutlined />}
                                        size={'small'} />
                                    <Notific ws={ws} />
                                    <Notes />
                                    <Valkurs />
                                    <Action />
                                    <Button
                                        size={'small'}
                                        shape={'circle'}
                                        type="primary"
                                        icon={<PoweroffOutlined />}
                                        onClick={signOut}
                                        danger
                                    />
                                </Space>
                            </Header>
                            <Content
                                style={{
                                    padding: 24,
                                    minHeight: 280,
                                    background: '#f5f5f5',
                                    maxHeight: '100%',
                                    overflow: "auto"
                                }}
                            >
                                <Routes>
                                    <Route path={'/'} element={<Dashboard />} />
                                    <Route path={'/services'} element={<Projects />} />
                                    <Route path={'/service/:id'} element={<SProj />} />
                                    <Route path={'/servicesadd'} element={<ProjectAdd />} />
                                    <Route path={'/pstatus'} element={<Pstatus />} />
                                    <Route path={'/tasks/*'} element={<PAllTasks />} />
                                    <Route path={'/events'} element={<Events />} />
                                    <Route path={'/sertypes'} element={<ServiceTypes />} />
                                </Routes>
                            </Content>
                        </Layout>
                    </Layout>
                </Spin>
            </FullScreen>
        </>
    );
}

export default Director;