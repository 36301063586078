import React, { useEffect } from 'react';
import { MainTable } from '../../../Components/MainTable/MainTable';
import { useCats } from './CatsStore';
import Add from './Add';
import Edit from './Edit';

function Categorys(props) {

    let { cats, columns, getCats, deleteCat, setEd } = useCats();
    useEffect(() => {
        getCats();
    }, []);
    return (
        <div>
            <MainTable
                pageTitle={"Категории"}
                pcolumns={columns}
                pdata={cats}
                add={<Add />}
                xwidth={1000}
                onDelete={(_, rec) => deleteCat(rec)}
                defkey={'id'}
                setEd={setEd}
                edit={<Edit />}
                onEdit={(rec) => { 
                    setEd(rec)
                 }}
            />
        </div>
    );
}

export default Categorys;