import { Table } from 'antd';
import axios from 'axios';
import { render } from 'katex';
import React, { useEffect, useState } from 'react';
import css from './ECard.module.css';

function Kelishi({user}) {
    let columns = [
        { title: 'Месяц', dataIndex: 'month', key: 'month',
            render: (_, rec) => {
                return rec.month === 0 ? 'Январь' :
                    rec.month === 1 ? 'Февраль' :
                    rec.month === 2 ? 'Март' :
                    rec.month === 3 ? 'Апрель' :
                    rec.month === 4 ? 'Май' :
                    rec.month === 5 ? 'Июнь' :
                    rec.month === 6 ? 'Июль' :
                    rec.month === 7 ? 'Август' :
                    rec.month === 8 ? 'Сентябрь' :
                    rec.month === 9 ? 'Октябрь' :
                    rec.month === 10 ? 'Ноябрь' :
                    rec.month === 11 ? 'Декабрь' : '';
            }
         },
        { title: 'Обшая рабочая время', dataIndex: 'worktime', key: 'worktime', width: 150,
            render: (_, rec) => {
                return rec.worktime ? (rec.worktime / 60) + ' ч' : 0  + ' ч';
            }
        },
        { title: '1', dataIndex: '1', key: '1', render: (_, rec) => <span className={rec['1'] > 0 ? css.success : css.empty}>{rec['1'] / 60} ч</span>},
        { title: '2', dataIndex: '2', key: '2', render: (_, rec) => <span className={rec['2'] > 0 ? css.success : css.empty}>{rec['2'] / 60} ч</span>},
        { title: '3', dataIndex: '3', key: '3', render: (_, rec) => <span className={rec['3'] > 0 ? css.success : css.empty}>{rec['3'] / 60} ч</span>},
        { title: '4', dataIndex: '4', key: '4', render: (_, rec) => <span className={rec['4'] > 0 ? css.success : css.empty}>{rec['4'] / 60} ч</span>},
        { title: '5', dataIndex: '5', key: '5', render: (_, rec) => <span className={rec['5'] > 0 ? css.success : css.empty}>{rec['5'] / 60} ч</span>},
        { title: '6', dataIndex: '6', key: '6', render: (_, rec) => <span className={rec['6'] > 0 ? css.success : css.empty}>{rec['6'] / 60} ч</span>},
        { title: '7', dataIndex: '7', key: '7', render: (_, rec) => <span className={rec['7'] > 0 ? css.success : css.empty}>{rec['7'] / 60} ч</span>},
        { title: '8', dataIndex: '8', key: '8', render: (_, rec) => <span className={rec['8'] > 0 ? css.success : css.empty}>{rec['8'] / 60} ч</span>},
        { title: '9', dataIndex: '9', key: '9', render: (_, rec) => <span className={rec['9'] > 0 ? css.success : css.empty}>{rec['9'] / 60} ч</span>},
        { title: '10', dataIndex: '10', key: '10', render: (_, rec) => <span className={rec['10'] > 0 ? css.success : css.empty}>{rec['10'] / 60} ч</span>},
        { title: '11', dataIndex: '11', key: '11', render: (_, rec) => <span className={rec['11'] > 0 ? css.success : css.empty}>{rec['11'] / 60} ч</span>},
        { title: '12', dataIndex: '12', key: '12', render: (_, rec) => <span className={rec['12'] > 0 ? css.success : css.empty}>{rec['12'] / 60} ч</span>},
        { title: '13', dataIndex: '13', key: '13', render: (_, rec) => <span className={rec['13'] > 0 ? css.success : css.empty}>{rec['13'] / 60} ч</span>},
        { title: '14', dataIndex: '14', key: '14', render: (_, rec) => <span className={rec['14'] > 0 ? css.success : css.empty}>{rec['14'] / 60} ч</span>},
        { title: '15', dataIndex: '15', key: '15', render: (_, rec) => <span className={rec['15'] > 0 ? css.success : css.empty}>{rec['15'] / 60} ч</span>},
        { title: '16', dataIndex: '16', key: '16', render: (_, rec) => <span className={rec['16'] > 0 ? css.success : css.empty}>{rec['16'] / 60} ч</span>},
        { title: '17', dataIndex: '17', key: '17', render: (_, rec) => <span className={rec['17'] > 0 ? css.success : css.empty}>{rec['17'] / 60} ч</span>},
        { title: '18', dataIndex: '18', key: '18', render: (_, rec) => <span className={rec['18'] > 0 ? css.success : css.empty}>{rec['18'] / 60} ч</span>},
        { title: '19', dataIndex: '19', key: '19', render: (_, rec) => <span className={rec['19'] > 0 ? css.success : css.empty}>{rec['19'] / 60} ч</span>},
        { title: '20', dataIndex: '20', key: '20', render: (_, rec) => <span className={rec['20'] > 0 ? css.success : css.empty}>{rec['20'] / 60} ч</span>},
        { title: '21', dataIndex: '21', key: '21', render: (_, rec) => <span className={rec['21'] > 0 ? css.success : css.empty}>{rec['21'] / 60} ч</span>},
        { title: '22', dataIndex: '22', key: '22', render: (_, rec) => <span className={rec['22'] > 0 ? css.success : css.empty}>{rec['22'] / 60} ч</span>},
        { title: '23', dataIndex: '23', key: '23', render: (_, rec) => <span className={rec['23'] > 0 ? css.success : css.empty}>{rec['23'] / 60} ч</span>},
        { title: '24', dataIndex: '24', key: '24', render: (_, rec) => <span className={rec['24'] > 0 ? css.success : css.empty}>{rec['24'] / 60} ч</span>},
        { title: '25', dataIndex: '25', key: '25', render: (_, rec) => <span className={rec['25'] > 0 ? css.success : css.empty}>{rec['25'] / 60} ч</span>},
        { title: '26', dataIndex: '26', key: '26', render: (_, rec) => <span className={rec['26'] > 0 ? css.success : css.empty}>{rec['26'] / 60} ч</span>},
        { title: '27', dataIndex: '27', key: '27', render: (_, rec) => <span className={rec['27'] > 0 ? css.success : css.empty}>{rec['27'] / 60} ч</span>},
        { title: '28', dataIndex: '28', key: '28', render: (_, rec) => <span className={rec['28'] > 0 ? css.success : css.empty}>{rec['28'] / 60} ч</span>},
        { title: '29', dataIndex: '29', key: '29', render: (_, rec) => <span className={rec['29'] > 0 ? css.success : css.empty}>{rec['29'] / 60} ч</span>},
        { title: '30', dataIndex: '30', key: '30', render: (_, rec) => <span className={rec['30'] > 0 ? css.success : css.empty}>{rec['30'] / 60} ч</span>},    
        { title: '31', dataIndex: '31', key: '31', render: (_, rec) => <span className={rec['31'] > 0 ? css.success : css.empty}>{rec['31'] / 60} ч</span>},
       
       
    ];
     
    let [data, setData] = useState([]);

    useEffect(() => {
        let y = new Date().getFullYear();
        axios.post('/musterone/' + user.id, {year: y}).then(data => {
            console.log(data);
            setData(data.data);
        });
    }, [user]);
    return (
        <>
            <Table dataSource={data} scroll={{ x: 2000 }} bordered size='small' columns={columns} />  
        </>
    );
}

export default Kelishi;