import {useEffect, useState} from "react";
import {Button, Col, Form, Input, Row, Select, TimePicker} from "antd";
import {useAuthHeader} from "react-auth-kit";
import axios from "axios";
import {message} from "antd/lib";
import {MainTable} from "../../../Components/MainTable/MainTable";

export function Smena() {
    let [rend, setRend] = useState(true)
    let [data, setData] = useState([])
    const [editData, setEditData] = useState([]);


    useEffect(() => {
        axios.get('/smenes').then(data => {
            setData(data.data)
        })
    }, [rend]);

    let columns = [
        {
            title: 'Название смен',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Начало',
            dataIndex: 'starthour',
            key: 'starthour',
            render: (_, rec) => {
                return <p>{rec.starthour}:{rec.startmin}</p>
            }
        },
        {
            title: 'Окончание',
            dataIndex: 'endhour',
            key: 'endhour',
            render: (_, rec) => {
                return <p>{rec.endhour}:{rec.endmin}</p>
            }
        },
        {
            title: 'Отдел',
            dataIndex: 'rang',
            key: 'rang',
        },
    ]
    const handleDel = (e, rec) => {
        axios.get('/smenesdel/' + rec.id).then(resData => {
            message.success(`Ma'lumot muvaffaqiyatli O'chirildi. ${resData.data}`);
            setRend(!rend)
        })
    }

    let onEdit = (rec) => {
        setEditData(rec)
        setRend(!rend)
    }
    return (
        <>
            <MainTable
                pdata={data}
                pcolumns={columns}
                pageTitle={'Смены'}
                add={<SmenAdd adder={setData} dt={data}/>}
                edit={<SmenEdit rend={rend} adder={setRend} iv={editData}/>}
                onDelete={handleDel}
                onEdit={onEdit}
                setEd={setEditData}
                defkey={'id'}
            />
        </>
    )
}

function SmenAdd({adder, dt}) {
    let [form] = Form.useForm()
    const onFinish = (values) => {
        let sh = String(values.start['$H'])
        let sm = String(values.start['$m'])
        let eh = String(values.end['$H'])
        let em = String(values.end['$m'])
        values.starthour = (sh > 1) ? sh : 0 + sh
        values.startmin = (sm > 1) ? sm : 0 + sm
        values.endhour = (eh > 1) ? eh : 0 + eh
        values.endmin = (em > 1) ? em : 0 + em

        console.log(values)
        axios.post('/smenes', values).then(resData => {
            let result = resData.data
            adder([result, ...dt])
            form.resetFields()
        })
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            <Form
                form={form}
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <fieldset>
                    <legend>Основное</legend>
                    <Form.Item label="Название" name="name">
                        <Input />
                    </Form.Item>
                    <Row gutter={15}>
                        <Col span={12}>
                            <Form.Item label="Начало" name="start">
                                <TimePicker size={'large'} minuteStep={5} format={'HH:mm'}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Окончание" name="end">
                                <TimePicker size={'large'} minuteStep={5} format={'HH:mm'}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item label="Группа" name='rang'>
                        <Select>
                            <Select.Option key={'1'} value={'Asosiy'}>Основной</Select.Option>
                        </Select>
                    </Form.Item>
                </fieldset>
                <hr/>
                <Form.Item>
                    <Button type={"primary"} size={'large'} htmlType="submit">Добавить</Button>
                </Form.Item>
            </Form>
        </>
    )
}

function SmenEdit({iv, adder, rend}) {
    const authHeader = useAuthHeader()
    const onFinish = (values) => {

        let sh = String(values.start['$H'])
        let sm = String(values.start['$m'])
        let eh = String(values.end['$H'])
        let em = String(values.end['$m'])
        values.starthour = (sh > 1) ? sh : 0 + sh
        values.startmin = (sm > 1) ? sm : 0 + sm
        values.endhour = (eh > 1) ? eh : 0 + eh
        values.endmin = (em > 1) ? em : 0 + em

        console.log(values)
        axios.post(process.env.REACT_APP_URL + '/smenesupd/' + values.id, values, {
            headers: {
                'Authorization': authHeader(),
                'Content-type': 'application/json'
            },
        }).then(resData => {
            let result = resData.data
            console.log(result)
            adder(!rend)
        })
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const [form] = Form.useForm()
    useEffect(() => {

        // iv.start = `12:15`
        console.log(iv)
        form.setFieldsValue(iv)
    }, [form, iv])
    return (
        <>
            <Form
                name={'editform'}
                form={form}
                initialValues={iv}
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <fieldset>
                    <legend>Main Data</legend>

                    <Form.Item hidden={true} name="id">
                        <Input/>
                    </Form.Item>
                    <Form.Item label="Name" name="name">
                        <Input placeholder={'Name'}/>
                    </Form.Item>
                    <Row gutter={15}>
                        <Col span={12}>
                            <Form.Item label="Start" name="start">
                                <TimePicker size={'large'} minuteStep={5} format={'HH:mm'}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="End" name="end">
                                <TimePicker size={'large'} minuteStep={5} format={'HH:mm'}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item label="Gruppa" name='rang'>
                        <Select>
                            <Select.Option key={'1'} value={'Asosiy'}>Asosiy</Select.Option>
                        </Select>
                    </Form.Item>
                </fieldset>
                <hr/>
                <Form.Item>
                    <Button type={"primary"} size={'large'} htmlType="submit">Qo'shish</Button>
                </Form.Item>
            </Form>
        </>
    )
}