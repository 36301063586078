import { MainTable } from "../../Components/MainTable/MainTable";
import { useEffect, useState } from "react";
import { AddEmployee } from "./AddEmployee";
import { EditEmploye } from "./EditEmploye";
import { useEmployee } from "./EmployeeState";
import { Modal } from "antd";
import { use } from "i18next";
import EmployeeCard from "./EmployeeCard";

export function Employee() {
    let [modal, setModal] = useState(false)
    let [current, setCurrent] = useState({})
    let { columns, employees, setEmployees, onDelete, onEdit, setEditData } = useEmployee()

    useEffect(() => {
        setEmployees()
    }, []);

    let onViev = (_, rec) => {
        setCurrent(rec)
        setModal(true)
    }

    return (
        <>
            <Modal open={modal} footer={false} title={"Карточка сотрудника " + current.firstname + ' ' + current.lastname} width={1200} onCancel={() => setModal(false)}>
                <EmployeeCard user={current} />
            </Modal>
            <MainTable
                onEdit={onEdit}
                onDelete={onDelete}
                setEd={setEditData}
                add={<AddEmployee />}
                edit={<EditEmploye />}
                defkey={'id'}
                pdata={employees}
                pcolumns={columns}
                viewBtn={true}
                xwidth={2000}
                pageTitle={"Сотрудники"}
                onView={onViev}
            />
        </>
    )
}