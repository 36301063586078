import {useEffect, useState} from "react";
import {useServiceTypes} from "./ServiseState";
import {Button, Form, Image, Input, message, Upload} from "antd";
import {useForm} from "antd/es/form/Form";
import {UploadFile} from "../../Components/Upload/UploadFile";

export const Edit = () => {

    let {onEdit, onFinishFailed, edData} = useServiceTypes()
    let [image, setImage] = useState(edData.img)
    let [form] = useForm()

    useEffect(() => {
        form.setFieldsValue(edData)
        setImage(edData.img)
    }, [edData]);
    return (
        <>
            <Form
                form={form}
                name="basic"
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={(values)=>onEdit({img: image, ...values})}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="ID"
                    name="id"
                >
                    <Input/>
                </Form.Item><Form.Item
                    label="Название"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your username!',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Картинка"
                >
                    <UploadFile img={image} setFile={setImage} />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Добавить
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}