import { current } from "@reduxjs/toolkit";
import { Button } from "antd";
import axios from "axios";
import { render } from "katex";
import { create } from "zustand";

export const useExecutes = create((set, get) => ({
    executes: [],
    payModal: false,
    current: {},
    setCurrent: (c)=>{
        set({current: c})
    },
    setPayModal: (s)=>{
        set({payModal: s})
    },
    columns: [
      
        {
            title: 'ФИО',
            dataIndex: 'firstname',
            key: 'firstname',
            render: (_, rec) => <>{rec.firstname} {rec.lastname}</>
        },
        {
            title: 'Задача',
            dataIndex: 'task_name',
            key: 'task_name',
        },
        {
            title: 'Количество выполненных задач (шт)',
            dataIndex: 'kpi',
            key: 'kpi',
        },
    ],
    columnssumm: [
        
        {
            title: 'ФИО',
            dataIndex: 'firstname',
            key: 'firstname',
            render: (_, rec) => <>{rec.firstname} {rec.lastname}</>
        },
        {
            title: 'Количество выполненных задач (шт)',
            dataIndex: 'kpi',
            key: 'kpi',
            render: (_, rec)=><>{rec.kpi} </>
        },
        {
            title: 'Процент от обшая суммы',
            dataIndex: 'paypercent',
            key: 'paypercent',
            render: (_, rec)=><>{rec.paypercent}%</>
        },
        {
            title: 'Выделяемая оплата',
            dataIndex: 'payment',
            key: 'payment',
        },
        {
            title: 'Описание к оплату',
            dataIndex: 'comment',
            key: 'comment',
        },
        {
            title: 'Добавить оплату',
            dataIndex: 'addpay',
            key: 'addpay',
            render: (_, rec) => <>
                <Button onClick={()=>{
                    get().setPayModal(true)
                    get().setCurrent(rec)
                    }} type="primary">Добавить оплату</Button>
            </>
        },
    ],
    getExec: (pid) => {
        axios.get('execs/' + pid).then(res => {
            if (res.status === 200) {
                set({ executes: res.data })
            }
        })
    }
}))