import { current } from "@reduxjs/toolkit";
import { Button, Space } from "antd";
import axios from "axios";
import { render } from "katex";
import { create } from "zustand";

export let useSalary = create((set, get) => ({
    data: [],
    current: {},
    checkmodal: false,
    year: new Date().getFullYear(),
    month: new Date().getMonth(),
    open: false,
    setOpen: (open) => set({
        open
    }),
    setYear: (year) => set({
        year
    }),
    setMonth: (month) => set({
        month
    }),
    setCheckModal: (checkmodal) => set({
        checkmodal
    }),
    setData: (data) => set({
        data
    }),
    getData: (year, month) => {
        axios.get('salary/' + get().year + '/' + get().month)
            .then(res => {
                set({
                    data: res.data
                })
            })
    },
    createSalary: (data) => {
        axios.post('checksalary', data)
            .then(res => {
                console.log(res);
                get().getData()
            })
    },
    writeSalary: (data) => {
        axios.put('salary/' + data.id, data)
            .then(res => {
                if (res.status === 200) {
                    get().getData()
                    get().setCheckModal(false)
                }
            })
    },
    payed: (rec) => {
        console.log(rec);
        axios.put('payed/' + rec.id, rec)
            .then(res => {
                if (res.status === 200) {
                    get().getData()
                }
            })
    },
    columns: [
        {
            title: 'ФИО',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => <>{record.firstname} {record.lastname}</>
        },
        {
            title: () => <div>На балансе <br /> (если отрицательный баланс сотрудник должен...)</div>,
            dataIndex: 'balance',
            key: 'balance',
            width: 200,
        },
        {
            title: 'Месяц',
            dataIndex: 'month',
            key: 'month',
            render: (_, rec) => {
                return (
                    (rec.month === 0) ? 'Январь' :
                        (rec.month === 1) ? 'Февраль' :
                            (rec.month === 2) ? 'Март' :
                                (rec.month === 3) ? 'Апрель' :
                                    (rec.month === 4) ? 'Май' :
                                        (rec.month === 5) ? 'Июнь' :
                                            (rec.month === 6) ? 'Июль' :
                                                (rec.month === 7) ? 'Август' :
                                                    (rec.month === 8) ? 'Сентябрь' :
                                                        (rec.month === 9) ? 'Октябрь' :
                                                            (rec.month === 10) ? 'Ноябрь' :
                                                                (rec.month === 11) ? 'Декабрь' : ''

                )
            }
        },
        {
            title: 'Оклад',
            dataIndex: 'salary',
            key: 'salary',
        },
        {
            title: 'Премия',
            dataIndex: 'bonus',
            key: 'bonus',
        },
        {
            title: 'Штрафы',
            dataIndex: 'fine',
            key: 'fine',
        },
        {
            title: 'Налоги',
            dataIndex: 'vat',
            key: 'vat',
        },
        {
            title: 'К оплату',
            dataIndex: 'payed',
            key: 'payed',
        },
        {
            title: 'Статус оплаты',
            dataIndex: 'paystatus',
            key: 'paystatus',
            render: (_, rec) => {
                return (
                    (rec.paystatus === 0) ? <span style={{ color: 'red', fontWeight: '700' }}>Не оплачено</span> :
                        (rec.paystatus === 1) ? <span style={{ color: 'green', fontWeight: '700' }}>Оплачено</span> : ''
                )
            }
        },
        {
            title: 'Инструменты',
            dataIndex: '',
            key: 'tools',
            render: (_, record) => {
                return (
                    <Space>
                       
                        <Button disabled={record.paystatus === 0 ? false : true} type="primary" style={{ backgroundColor: 'goldenrod' }} onClick={() => {
                            set({
                                current: record
                            })
                            get().setCheckModal(true)

                        }}>Регистрировать зарплату</Button>
                        <Button disabled={record.paystatus === 0 ? false : true} type="primary" style={{ backgroundColor: 'green' }} onClick={() => { get().payed(record) }}>Оплатить</Button>
                    </Space>
                )
            }
        }
    ],

}))