import { Button, Form, Input } from 'antd';
import React, { useEffect } from 'react';
import { useCats } from './CatsStore';

function Edit(props) {
    let { updateCat, ed } = useCats();
    let [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue(ed);
    }, [ed]);
    return (
        <div>
            <Form
                form={form}
                name="basic"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                onFinish={(v) => {
                    updateCat(v);
                }}
            >
                <Form.Item
                    label="Название категории"
                    name="id"
                    hidden={true}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Название категории"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: 'Введите название категории!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Цвет"
                    name="color"
                >
                    <Input type='color' />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Добавить
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default Edit;