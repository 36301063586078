import { useAuthHeader } from "react-auth-kit";
import {
    PoweroffOutlined,
    CalendarOutlined,
    IdcardOutlined,
    RightCircleOutlined,
    LeftCircleOutlined,
    ExpandOutlined,
    CompressOutlined,
    CheckSquareOutlined, ClockCircleOutlined,

} from '@ant-design/icons';
import { Layout, Menu, theme, Button, Space, message, Spin } from 'antd';
import React, { useState } from 'react';
import { NavLink, Route, Routes, useHref } from "react-router-dom";
import { Employee } from "../../Pages/Employee/Employee";
import logo from './../../assets/mayaknewlogo.png'
import { Events } from "../../Pages/Events/Events";
import axios from "axios";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { Action } from "../../Components/Action";
import { Muster } from "../../Pages/Employee/Musters/Muster";
import { Smena } from "../../Pages/Employee/Musters/Smena";
import { Notific } from "../MainLayout/Notific";
import { Notes } from "../MainLayout/Notes";
import { Valkurs } from "../MainLayout/Valkurs";

const { Header, Sider, Content } = Layout;
function Hr({ ws, signOut }) {

    let [loader, setLoader] = useState(true)
    const authHeader = useAuthHeader()
    const handle = useFullScreenHandle();
    axios.defaults.baseURL = process.env.REACT_APP_URL;
    axios.defaults.headers.common['Authorization'] = authHeader();
    axios.defaults.headers.post['Content-Type'] = 'application/json';

    let activeLink = useHref()

    axios.interceptors.request.use(
        request => {
            return request;
        },
        err => {
            throw err;
        },
    );
    axios.interceptors.response.use(response => {
        if (response.status === 200) {
            setLoader(false)
        }
        return response;
    }, error => {
        if (error === null) {
            message.error("Bunday Ma'lumotlar topilmadi")
        } else if (error.response.status === 401) {
            signOut()
        }
        return error;
    });

    const [collapsed, setCollapsed] = useState(false);
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const fullSC = () => {
        (handle.active) ? handle.exit() : handle.enter()
    }

    return (
        <>
            <FullScreen handle={handle}>
                <Spin spinning={loader} style={{ height: '100vh' }} tip="Loading...">
                    <Layout style={{ height: '100vh' }}>
                        <Sider style={{ backgroundColor: '#fff' }} width={250} trigger={null} collapsible
                            collapsed={collapsed}
                            collapsedWidth={0}>
                            <Menu
                                theme="light"
                                mode="inline"
                                defaultSelectedKeys={activeLink}
                                items={[
                                    {
                                        key: "/main",
                                        icon: <img width={'30px'} src={logo} alt="Mayak" />,
                                        label: <NavLink to="/main">MAYAK MEDIA MARKET</NavLink>,
                                    },
                                    {
                                        key: "/main/employee",
                                        icon: <IdcardOutlined />,
                                        label: <NavLink to="/main/">Сотрудники</NavLink>,
                                    },
                                    {
                                        key: "/main/muster",
                                        icon: <CheckSquareOutlined />,
                                        label: <NavLink to="/main/muster">Табель</NavLink>,
                                    },
                                    {
                                        key: "/main/change",
                                        icon: <ClockCircleOutlined />,
                                        label: <NavLink to="/main/change">Смены</NavLink>,
                                    },
                                    {
                                        key: "/main/events",
                                        icon: <CalendarOutlined />,
                                        label: <NavLink to="/main/events">Событии</NavLink>,
                                    },
                                ]}
                            />
                        </Sider>
                        <Layout className="site-layout" style={{ backgroundColor: 'rgb(240, 242, 245)' }}>
                            <Header
                                style={{
                                    padding: '0px 25px',
                                    height: '45px',
                                    background: colorBgContainer,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                {React.createElement(collapsed ? RightCircleOutlined : LeftCircleOutlined, {
                                    className: 'trigger',
                                    onClick: () => setCollapsed(!collapsed),
                                })}
                                <Space className="right">
                                    <Button onClick={fullSC} type={'primary'} shape={'circle'}
                                        icon={handle.active ? <CompressOutlined /> : <ExpandOutlined />} size={'small'} />
                                    <Notific ws={ws} />
                                    <Notes />
                                    <Valkurs />
                                    <Action />
                                    <Button
                                        size={'small'}
                                        shape={'circle'}
                                        type="primary"
                                        icon={<PoweroffOutlined />}
                                        onClick={signOut}
                                        danger
                                    />
                                </Space>
                            </Header>
                            <Content
                                style={{
                                    padding: 24,
                                    minHeight: 280,
                                    background: 'rgb(240, 242, 245)',
                                    maxHeight: '100%',
                                    overflow: "auto"
                                }}
                            >
                                <Routes>
                                    <Route path={'/'} element={<Employee />} />
                                    <Route path={'/muster'} element={<Muster />} />
                                    <Route path={'/change'} element={<Smena />} />
                                    <Route path={'/events'} element={<Events />} />
                                </Routes>
                            </Content>
                        </Layout>
                    </Layout>
                </Spin>
            </FullScreen>
        </>
    );
}

export default Hr;