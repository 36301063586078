import { Badge, Calendar } from 'antd';
import axios from 'axios';
import React, { useEffect } from 'react';


function EmMuster({ user}) {

   

    const dateCellRender = (value) => {
        const listData = [125, 12];
        return (
           <div style={{ backgroundColor: 'aqua' }}>Salom</div>
        );
    };
    const cellRender = (current, info) => {
        // console.log(current, info);
        
        if (info.type === 'date' && current.$D === 10) return dateCellRender(current);
        return info.originNode;
    };

    return (
        <>
            <Calendar cellRender={cellRender} mode='month' onPanelChange={(a)=>{
                console.log(a)
            }}  />
        </>
    );
}

export default EmMuster;