import React from 'react';
import { MainTable } from '../../Components/MainTable/MainTable';
import Add from './Add';

function Test(props) {
    let data = [
        {
            id: 1,
            name: "Test",
            age: 20
        },
        {
            id: 2,
            name: "Test",
            age: 20
        },
        {
            id: 3,
            name: "Test",
            age: 20
        }
    ]

    let cols = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Age",
            dataIndex: "age",
            key: "age",
        }
    ]

    let onDelete = (_, id) => {
        console.log(id)
    }

    return (
        <div>
            <MainTable
                pageTitle={"Test"}
                add={<Add />}
                pcolumns={cols}
                pdata={data}
                onDelete={onDelete}
                defkey={"id"}
                edit={<>EDIT</>}
                onEdit={() => console.log("Edit")}
                setEd={() => console.log("Set Edit")}
                viewBtn={true}
                onView={() => console.log("View")}
            />
        </div>
    );
}

export default Test;