import {Button, Image, message, Upload} from "antd";
import {useEffect, useState} from "react";

export const UploadFile = ({setFile, img}) => {
    let [image, setImage] = useState('')

    useEffect(() => {
        setImage(img)
    }, [img]);
    console.log(image)
    const props = {
        name: 'image',
        action: process.env.REACT_APP_MAINURL + '/api/file',
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file.response, info.fileList);
            }
            if (info.file.status === 'done') {
                setFile(info.file.response.data.image)
                setImage(info.file.response.data.image)
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };
    return (
        <>
            <Upload {...props}>
                <Button>Загрузка файла</Button>
                <Image src={process.env.REACT_APP_MAINURL + image} width={100} />
            </Upload>
        </>
    )
}