import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import axios from "axios";
import {Button, Col, Form, Input, InputNumber, Row, Select} from "antd";
import TextArea from "antd/es/input/TextArea";

export function PTaskAdd({adder, dt}) {
    let params = useParams();
    let [vexa, setVexa] = useState([]);
    let [data, setData] = useState([])

    useEffect(() => {
        axios.get('/taskgroups/' + params.id).then(data => setVexa(data.data))
        axios.get('/employees').then(data => {
            setData(data.data)
        })
    }, []);
    const onFinish = (values) => {
        values.project_id = params.id;
        values.execut = JSON.stringify(values.execut)
        console.log(values)
        axios.post('/tasks', values).then(resData => {
            if(resData.status === 200){
                adder(!dt)
            }
        })
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            <Form
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <fieldset>
                    <legend>Основная Информация</legend>
                    <Form.Item label="Название" name="name">
                        <Input placeholder={'Name'}/>
                    </Form.Item>
                    <Row gutter={15}>
                        <Col span={12}>
                            <Form.Item label="Начало" name="start">
                                <Input type={"date"}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Окончание" name="end">
                                <Input type={"date"}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item label="Группа Задач" name='task_group_id'>
                        <Select>
                            {vexa?.map(item => <Select.Option key={item.id}
                                                              value={item.id}>{item.name}</Select.Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Исполнители" name="execut">
                        <Select
                            mode="multiple"
                            size={'middle'}
                            placeholder="Please select"
                            // defaultValue={['a10', 'c12']}
                            // onChange={() => console.log('a')}
                            style={{width: '100%'}}
                            options={data.map(x => ({label: x.firstname + ' ' + x.lastname, value: x.id}))}
                        />
                    </Form.Item>
                    <Form.Item label="Информация" name="description">
                        <TextArea placeholder={'Description'}/>
                    </Form.Item>
                </fieldset>
                <hr/>
                <Form.Item>
                    <Button type={"primary"} size={'large'} htmlType="submit">Добавить</Button>
                </Form.Item>
            </Form>
        </>
    )
}