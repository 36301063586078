import {Avatar, Badge, Button, Drawer, List} from "antd";
import {ApiOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import {useSocket} from "../SocketStore";

export const Action = () => {
    let [open, setOpen] = useState(false)
    let [messCount, setCount] = useState(0)

    let {ws, connect} = useSocket()

    useEffect(() => {
        if (connect) {
            ws.onmessage = () => {
                setCount(++messCount)
            }
        }
    }, [connect]);


    const data = [
        {
            date: '12/05/2024',
            desc: 'Salom dunyo',
            link: '/'
        },
        {
            date: '12/05/2024',
            desc: 'Salom dunyo',
            link: '/'
        },
        {
            date: '12/05/2024',
            desc: 'Salom dunyo',
            link: '/'
        },
        {
            date: '12/05/2024',
            desc: 'Salom dunyo',
            link: '/'
        },
    ];

    return (
        <>
            <Badge count={messCount} size={'small'} showZero>
                <Button
                    size={'small'}
                    shape={'circle'}
                    type="primary"
                    icon={<ApiOutlined/>}
                    onClick={() => setOpen(true)}
                />
            </Badge>

            <Drawer open={open} onClose={() => setOpen(false)} title={"Последные действии"}>
                <List
                    itemLayout="horizontal"
                    dataSource={data}
                    renderItem={(item, index) => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${index}`}/>}
                                title={<a href={item.link}>{item.date}</a>}
                                description={item.desc}
                            />
                        </List.Item>
                    )}

                />
            </Drawer>

        </>
    )
}