import {create} from "zustand";
import axios from "axios";

export let useProject = create((set, get) => ({
    projects: [],
    setProjects: () => {
        axios.get('/projects').then(res => {
            if (res.status === 200) {
                set({projects: res.data})
            }
        })
    },
    addProject: (values) => {
        axios.post('/projects', values).then(res => {
            get().setProjects()
        })
    },
    onDelete: (id) => {
        axios.delete('/projects/' + id).then(res => {
            get().setProjects()
        })
    }
}))