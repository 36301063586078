import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Col, Form, Input, Row, Select, message} from 'antd';
import axios from "axios";
import TextArea from "antd/es/input/TextArea";
import {MaskedInput} from "antd-mask-input";
import {useLeads} from "./LeadState";


export function LeadAdd({rend, setRend, stdata}) {
    let [vils, setVils] = useState([])
    let {addLead} = useLeads()

    let [form] = Form.useForm()
    useEffect(() => {
        axios.get('/regdata').then(res => setVils(res.data.regions))
    }, [])
    const onFinish = (values) => {
        addLead(values)
        form.resetFields()
    };


    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            <Form
                form={form}
                labelCol={{span: 24}}
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <fieldset>
                    <legend>Основня информация</legend>
                    <Row gutter={10}>
                        <Col span={6}>
                            <Form.Item
                                label="Телефон"
                                name="mobile"
                                rules={[
                                    {required: true, message: 'Please input your password!'},
                                ]}
                            >
                                <Input type={'tel'}/>
                            </Form.Item>
                        </Col>
                        <Col span={18}>
                            <Form.Item
                                label="ФИО"
                                name="client_name"
                                rules={[
                                    {required: true, message: 'Please input your username!'},
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={10}>
                        <Col span={12}>
                            <Form.Item
                                label="Название организации"
                                name="company_name"
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Номер организации"
                                name="cell"
                            >
                                <Input type={'tel'}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={10}>
                        <Col span={12}>
                            <Form.Item
                                label="Область"
                                name="region_id"
                                rules={[
                                    {required: true, message: 'Please input your password!'},
                                ]}
                            >
                                <Select
                                    // onChange={handleChange}
                                    options={vils.map((st) => ({value: st.id, label: st.nameru}))
                                    }/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Адресс"
                                name="address"
                                rules={[
                                    {required: true, message: 'Please input your password!'},
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Row>
                </fieldset>


                <Form.Item>
                    <Button size={'large'} style={{width: '100%'}} type="primary" htmlType="submit">
                        Saqlash
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}