import React from "react";
import "gantt-task-react/dist/index.css";
import {ViewMode} from "gantt-task-react";

export const ViewSwitcher = ({
                                 onViewModeChange,
                                 onViewListChange,
                                 isChecked,
                             }) => {
    return (
        <div className="ViewContainer">
            <button
                className="Button"
                onClick={() => onViewModeChange(ViewMode.Hour)}
            >
                Чась
            </button>
            <button
                className="Button"
                onClick={() => onViewModeChange(ViewMode.QuarterDay)}
            >
                Четверть дня
            </button>
            <button
                className="Button"
                onClick={() => onViewModeChange(ViewMode.HalfDay)}
            >
                Полдень
            </button>
            <button className="Button" onClick={() => onViewModeChange(ViewMode.Day)}>
                День
            </button>
            <button
                className="Button"
                onClick={() => onViewModeChange(ViewMode.Week)}
            >
                Неделя
            </button>
            <button
                className="Button"
                onClick={() => onViewModeChange(ViewMode.Month)}
            >
                Месяц
            </button>
            <button
                className="Button"
                onClick={() => onViewModeChange(ViewMode.Year)}
            >
                Год
            </button>
            <button
                className="Button"
                onClick={() => onViewModeChange(ViewMode.QuarterYear)}
            >
                Квартал
            </button>
            <div className="Switch">
                <label className="Switch_Toggle">
                    <input
                        type="checkbox"
                        defaultChecked={isChecked}
                        onClick={() => onViewListChange(!isChecked)}
                    />
                    <span className="Slider"/>
                </label>
                Показать список задач
            </div>
        </div>
    );
};