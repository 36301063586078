import { Button, DatePicker, Input, Modal, Table } from 'antd';
import React, { useEffect } from 'react';
import { useSalary } from './SalaryStore';
import Add from './Add';
import CheckSalary from './CheckSalary';

function Salary() {
    let { data, getData, columns, checkmodal, setCheckModal, current, year, month, setMonth, setYear, open, setOpen } = useSalary();
    useEffect(() => {
        console.log(year, month);
        getData(year, month)
    }, [month]);


    let monthName = (month) => {
        let months = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']
        return months[month]
    }
    const onChange = (date) => {
        let d =  new Date(date);
        console.log(d.getFullYear(), d.getMonth());

        
        setYear(d.getFullYear())
        setMonth(d.getMonth())
    };
    return (
        <>
            <Modal footer={false} title="Записать зарплата к сотруднику" open={checkmodal} onCancel={() => setCheckModal(false)} >
                <CheckSalary setYear={setYear} setMonth={setMonth} current={current} />
            </Modal>
            <Modal footer={false} title="Сформировать Зарплата к сотрудникам" open={open} onCancel={() => setOpen(false)} >
                <Add setYear={setYear} setMonth={setMonth} />
            </Modal>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h1>Зарплата сотрудников за {monthName(month)}</h1>
                <Input type='month' onInput={(e)=> onChange(e.target.value)}  />
                <Button type="primary" onClick={() => setOpen(true)}>Сформировать</Button>
            </div>
            <Table bordered columns={columns} dataSource={data} rowKey={'id'} />
        </>
    );
}

export default Salary;