import { Table } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

function EmPayment({user}) {

    let [salary, setSalary] = useState([])
    useEffect(() => {
        axios.post('/salary/' + user.id, {year: new Date().getFullYear()}).then(data => {
            setSalary(data.data)
        })
    }, [user]);


    let columns = [
        { title: 'Месяц', dataIndex: 'month', key: 'month' },
        { title: 'Зарплата', dataIndex: 'salary', key: 'salary' },
        { title: 'Премия', dataIndex: 'bonus', key: 'bonus' },
        { title: 'Штраф', dataIndex: 'fine', key: 'fine' },
        { title: 'Налог', dataIndex: 'vat', key: 'vat' },
        { title: 'Итого', dataIndex: 'payed', key: 'payed' },

    ]

    return (
        <>
            <h3>Заработная плата сотрудника</h3>
            <Table columns={columns} dataSource={salary} pagination={false} rowKey={'id'} />
        </>
    );
}

export default EmPayment;