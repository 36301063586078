import {MainTable} from "../../Components/MainTable/MainTable";
import {useServiceTypes} from "./ServiseState";
import {useEffect} from "react";
import {Add} from "./Add";
import {Edit} from "./Edit";

export const ServiceTypes = () => {

    let {serviceTypes, setService, columns, onDelete, setEddata} = useServiceTypes()
    useEffect(() => {
        setService()
    }, []);

    let onEdit = (data)=>{
        setEddata(data)
    }

    return (
        <>
            <MainTable
                pageTitle={"Типы Услуг"}
                pdata={serviceTypes}
                pcolumns={columns}
                add={<Add />}
                defkey={'id'}
                onDelete={onDelete}
                edit={<Edit />}
                onEdit={onEdit}
                setEd={setEddata}
            />
        </>
    )
}