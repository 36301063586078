import { Button, Card, Checkbox, Descriptions, Divider, Form, Input, List, message, Modal, Space, Upload } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from "antd/es/form/Form";
import { useSocket } from "../../../SocketStore";
import { SaveOutlined, UploadOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { useParams } from "react-router-dom";
import { FileCard } from "../../../Components/FileCard/FileCard";
import { TaskFiles } from "./TaskFiles";
import { useTasks } from "./TasksState";

export const TaskDetails = ({ pm_task_id, render }) => {
    let [rend, setRend] = useState(false)
    let [microtasks, setMicrotasks] = useState([])
    
    let { ws, connect, sendMessage } = useSocket()
    let [filename, setFilename] = useState('')
    let [file, setFile] = useState('')
    let [files, setFiles] = useState([])

    let { setData } = useTasks()

    let para = useParams()

    // console.log(para);


    let [currentMicroTask, setCurrentMicroTask] = useState({})

    useEffect(() => {
        axios.get('taskdetails/' + pm_task_id).then(res => {
            if (res.status === 200) {
                setMicrotasks(res.data)
            }
        })


        axios.get('taskfiles/' + p.id + '/' + pm_task_id).then(res => {
            if (res.status === 200) {
                setFiles(res.data)
            }
        })

    }, [rend, render, pm_task_id]);
    let p = useParams()
    let [form] = useForm()

   
    const props = {
        name: 'image',
        action: process.env.REACT_APP_MAINURL + '/api/file',
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                // console.log(info.file);
                setFile(info.file.response.data.image)
                setFilename(info.file.name)
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    let onDelete = (id) => {
        axios.delete('taskfiles/' + id).then(res => {
            if (res.status === 200) {
                setRend(!rend)
            }
        })
    }

    return (
        <>
           
            <div style={{ height: '70vh', overflow: 'auto', borderRight: '1px solid silver', padding: '0 15px' }}>
                <h4>Микро Задачи</h4>
                <Form
                    form={form}
                    onFinish={(values) => {
                        values.pm_task_id = pm_task_id
                        console.log(values)
                        axios.post('taskdetails', values).then(res => {
                            if (res.status === 200) {
                                setRend(!rend)
                                form.resetFields()

                            }
                        })
                    }}>
                    <Form.Item name={'text'}>
                        <Space.Compact
                            style={{
                                width: '100%',
                            }}
                        >
                            <Input placeholder={"Введите микрозадачу"} />
                            <Button type="primary" htmlType={'submit'} icon={<SaveOutlined />} />
                        </Space.Compact>
                    </Form.Item>
                </Form>
                <List
                grid={{
                    gutter: 16,
                    column: 2,
                  }}
                    size="large"
                    dataSource={microtasks}
                    renderItem={(item) => <List.Item style={{ padding: '0', border: 'none' }}>
                        <Card style={{
                            width: '100%',
                            marginBottom: '15px',
                            boxShadow: '0px 0px 15px -5px gray',
                            backgroundColor: item.status === 1 ? 'lightgreen' : item.status === 2 ? 'lightcoral' : 'white'
                        }} bodyStyle={{ padding: '0 15px' }} title={
                            <>
                                {item.text}
                            </>
                        }>
                            <p><b>Время выполнение: </b>{item.exe_date}</p>
                            {/* <p><b>Информация о выполнение: </b>{item.exe_comment}</p> */}
                            <h4>Файлы по выполнению...</h4>
                            <TaskFiles render={render} task_id={item.id} />
                            <Divider />
                            <p><b>Исполнитель: </b>{item.executor}</p>
                        </Card>

                    </List.Item>}
                />
            </div>
        </>
    )
}