import {Avatar, Badge, Button, Card, Drawer, Form, Input, Modal, Space} from "antd";
import {useEffect, useState} from "react";
import {
    CloseOutlined,
    FormOutlined,
} from '@ant-design/icons';
import TextArea from "antd/es/input/TextArea";
import axios from "axios";

export function Notes() {
    const [open, setOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    let [notes, setNotes] = useState([])
    let [r, setR] = useState(true)

    useEffect(()=>{
        axios.get('notes').then(res => {
            if(res.status === 200){ setNotes(res.data) }
        })
    }, [r])
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    const onFinish = (v) => {
        axios.post('noteadd', v).then(res => {
            if(res.status === 200){
                setR(!r)
            }
        })
    }
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const notedel = (id)=>{
        axios.get('notedel/' + id).then(res =>{
            if(res.status === 200) setR(!r)
        })
    }
    return (
        <>
            <Badge size={'small'} count={notes.length} offset={[-1, 3]}>
                <Avatar size="small" style={{backgroundColor: 'blue'}} onClick={showDrawer} icon={<FormOutlined/>}/>
            </Badge>
            <Drawer title={<Space style={{justifyContent: 'space-between', width: '100%'}}>
                <span>Заметки</span>
                <Button onClick={showModal} type={'primary'}>Добавить</Button>
            </Space>} width={'1200px'} placement="right" onClose={onClose} open={open}>
                <Modal footer={false} title="Добавить заметку" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                    <Form labelCol={{span: 24}} wrapperCol={{span: 24}} onFinish={onFinish}>
                        <Form.Item
                            label="Заметка"
                            name="note"
                            rules={[
                                {
                                    required: true,
                                    message: 'Название обязательно!',
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="Описание"
                            name="notedesc"
                        >
                            <TextArea/>
                        </Form.Item>
                        <Form.Item
                            label="Цвет"
                            name="notecolor"
                        >
                            <Input type={'color'}/>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Добавить
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>


                <div style={{display:'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '15px'}}>
                    {notes.map(not => {
                        return (<Card key={not.id} title={not.note} extra={<Button onClick={()=>notedel(not.id)} type={'primary'} danger icon={<CloseOutlined />} shape={"circle"} size={'small'} />}>
                                <div style={{backgroundColor: not.notecolor, position: 'absolute', left: '0', width: '10px', height: '100%', top: '0', borderRadius: '10px 0 0 10px'}}></div>
                                <p>{not.notedesc}</p>
                                <span>{not.date}</span>
                            </Card>)
                    })}

                </div>

            </Drawer>
        </>
    )
}