import { message } from "antd";
import {create} from "zustand";

export let useSocket = create((set, get) => ({
    ws: null,
    connect: false,
    messStatus: true,
    setted: false,
    message: {},
    setMessage: (message) => set({message}),
    onConnect: () => {
        let ws = new WebSocket('wss://soc.profes.uz')
        ws.onopen = () => {
            set({ws: ws})
            set({connect: true})
            set({setted: !get().setted})
        }
        ws.onerror = ()=>{
            set({connect: false})
        }
        ws.onclose = ()=>{
            set({connect: false})
        }
    },

    sendMessage: (message)=>{
        if(get().connect){
            get().ws.send(JSON.stringify(message))
            set({messStatus: !get().messStatus})
        }
    }

}))