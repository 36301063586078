import {Card} from "antd";
import Meta from "antd/es/card/Meta";
import {DeleteOutlined, DownloadOutlined, EyeOutlined} from "@ant-design/icons";

export const FileCard = ({file, onDelete}) => {
    let type = file.filename.split('.').at(-1)
    let date = new Date(file.updated_at)
    let loaddate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} | ${date.getHours()}:${date.getMinutes()}`
    return (
        <Card
            style={{width: '100%', marginBottom: '15px'}}
            actions={[
                <a download={true} href={process.env.REACT_APP_MAINURL + file.filepath} target={'_blank'}>
                    <DownloadOutlined key="view" style={{color: 'green'}}/>
                </a>,
                <DeleteOutlined onClick={() => onDelete(file.id)} key="delete" style={{color: 'red'}}/>,
            ]}
        >
            <Meta
                avatar={<img alt={'file'} src={'/fileTypes/icons/classic/' + type + '.svg'} style={{width: '50px'}}/>}
                title={file.filename}
                description={<>
                    {file.comment}
                    <br/>
                    <b>{loaddate}</b>
                    <br/>
                    <i>{file.fullname}</i>
            </>}
            />
        </Card>

    )
}