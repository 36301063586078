import {Button, Form, Input} from "antd";
import axios from "axios";
import {message} from "antd/lib";

export const Add = ({setRend, rend}) => {
    const onFinish = (values) => {
        console.log('Success:', values);
        axios.post('pmtaskstatus', values)
            .then(res=> (res.status === 200)
                ? setRend(!rend)
                : message.error("Ma'lumotni joylashda hatolik"))
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    return (
        <>
            <Form
                name="basic"
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="Название этапа"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your username!',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Добавить
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}