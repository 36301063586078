import {Button, Col, Form, Input, InputNumber, Row} from "antd";
import axios from "axios";
import {useParams} from "react-router-dom";

export function PVexaAdd({rend, setRend}) {
    let params = useParams();
    const onFinish = (values) => {
        values.project_id = params.id;
        axios.post('/taskgroups', values).then(res => {
            if (res.status === 200) {
                setRend(!rend)
            }
        })
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    return (
        <>
            <Form
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item label="Название" name="name">
                    <Input placeholder={'Название группы задач'}/>
                </Form.Item>
                <Row gutter={15}>
                    <Col span={12}>
                        <Form.Item label="Начало" name="start">
                            <Input type={"date"}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Окончание" name="end">
                            <Input type={"date"}/>
                        </Form.Item>
                    </Col>
                </Row>
                <hr/>
                <Form.Item>
                    <Button type={"primary"} size={'large'} htmlType="submit">Добавить</Button>
                </Form.Item>
            </Form>
        </>
    )
}