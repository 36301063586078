import { Column } from '@ant-design/plots';
import { Button, Card, Col, Divider, Image, List, Row, Space, Table } from "antd";
import axios from 'axios';
import React, { useEffect, useState } from "react";


export function Events() {
    let [me, setMe] = useState({
        projects: [],
        salaryHistory: [],
        recentTasks: [],
    });

    useEffect(() => {
        axios.get('me')
            .then((res) => {
                setMe(res.data);
            });
    }, []);

    console.log(me);



    // Ustunli diagramma uchun konfiguratsiya
    const config = {
        data: me.projects.map((project) => ({
            projectName: project.project_name,
            kpi: project.kpi,
            salary: project.payment,
        })),
        xField: 'salary',
        yField: 'kpi',
        seriesField: 'projectName',
        colorField: 'projectName',
        color: ['#1890FF', '#13C2C2', '#52C41A'],
        legend: {
            position: 'bottom',
        },
        columnStyle: { radius: [20, 20, 0, 0] },
        xAxis: {
            label: {
                labelFormatter: '.0%',
            },
        },
        label: {
            text: (d) => `${d.salary} sum`,
            textBaseline: 'bottom',
        },
    };

    // payed
    // paystatus
    // vat
    const columns = [
        {
            title: 'Месяц',
            dataIndex: 'month',
            key: 'month',
        },
        {
            title: 'Год',
            dataIndex: 'year',
            key: 'year',
        },
        {
            title: 'Заработная плата',
            dataIndex: 'salary',
            key: 'salary',
        },
        {
            title: 'Надбавка',
            dataIndex: 'bonus',
            key: 'bonus',
        },
        {
            title: 'Штраф',
            dataIndex: 'fine',
            key: 'fine',
        },
        {
            title: 'Налог от дохода',
            dataIndex: 'vat',
            key: 'vat',
        },
        {
            title: 'Оплачено',
            dataIndex: 'payed',
            key: 'payed',
            render: (text, record) => (
                <>
                    <Button type='primary' danger={(record.paystatus && record.payed > 0) ? false : true} block>{record.payed}</Button>
                </>
            ),
        },
    ];
    // console.log(localizer)
    return (
        <>
            <div style={{ padding: 20 }}>
                <Card>
                    <Row gutter={16}>
                        <Col span={16}>
                            <Row gutter={16}>
                                <Col span={10}>
                                    <Image width={150} src={me?.img} />
                                    <h3>{me?.lastname} {me?.firstname} {me?.surname}</h3>
                                    <Space direction='vertical'>
                                        <Button type='default' block>Зарплата: {me?.balance} сум</Button>
                                        <Button type='default' block>ИНПС: {me?.jshshir}</Button>
                                        <a href={"tel:" + me?.phone}><Button block type='primary'>Телефон: {me?.phone}</Button></a>
                                    </Space>
                                </Col>
                                <Col span={14}>
                                    <Card title="Зарплата по проектам">
                                        <Column {...config} />
                                    </Card>

                                </Col>
                                <Col span={24}>
                                    <Card title="Зарплата по месяцам (последные 12 месяцов)">
                                        <Table
                                            size='small'
                                            dataSource={me.salarys}
                                            columns={columns}
                                            pagination={false}
                                            rowKey="month"
                                        />
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8}>
                            <Card title="Последные выполненные задачи">
                                <List
                                    size='small'
                                    itemLayout="horizontal"
                                    dataSource={me.recentTasks}
                                    style={{ height: '1000px', overflow: 'auto' }}
                                    renderItem={(item) => (
                                        <List.Item>
                                            <List.Item.Meta
                                                title={item.text}
                                                description={
                                                    <>
                                                        <p>Комментарий: {item.exe_comment}</p>
                                                        <p>Время выполнение: {item.exe_date}</p>
                                                    </>
                                                }
                                            />
                                        </List.Item>
                                    )}
                                />
                            </Card>

                        </Col>
                    </Row>
                </Card>
            </div>
        </>
    )
}