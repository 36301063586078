import {Button, Checkbox, Form, Input, message, Space, Upload} from "antd";
import {FileCard} from "../../../Components/FileCard/FileCard";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import axios from "axios";
import {PlusOutlined, SaveOutlined, UploadOutlined} from "@ant-design/icons";
import {Drawer} from "antd/lib";

export const StockFiles = () => {
    let [dopen, setDopen] = useState(false)
    let [rend, setRend] = useState(false)
    let p = useParams()
    let [file, setFile] = useState('')
    let [files, setFiles] = useState([])
    let [filename, setFilename] = useState('')
    const props = {
        name: 'image',
        action: process.env.REACT_APP_MAINURL + '/api/file',
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                // console.log(info.file);
                setFile(info.file.response.data.image)
                setFilename(info.file.name)
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };


    useEffect(() => {
        axios.get('projectfiles/' + p.id).then(res => {
            if (res.status === 200) {
                setFiles(res.data)
            }
        })
    }, [rend]);

    let onDelete = (id) => {
        axios.delete('taskfiles/' + id).then(res => {
            if (res.status === 200) {
                setRend(!rend)
            }
        })
    }
    axios.defaults.mode = 'no-cors'
    axios.defaults.headers.post['Content-Type'] = 'application/json, *'


    return (
        <>
            <Drawer open={dopen} onClose={() => setDopen(false)}>

            </Drawer>
            <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '25px'}}>
                <h4>Все исходные файлы для запуска проекта</h4>
                <Button icon={<PlusOutlined/>} type={'primary'} onClick={() => setDopen(true)} shape={'circle'}/>
            </div>
            <Form
                // form={form}
                onFinish={(values) => {
                    values.filename = filename
                    values.filepath = file
                    values.project_id = p.id
                    values.type = 'project'
                    console.log(values)
                    axios.post('projectfiles', values).then(res => {
                        if (res.status === 200) {
                            setRend(!rend)
                        }
                    })
                }}>
                <Space.Compact
                    style={{
                        width: '100%',
                    }}
                >
                    <Upload {...props}>
                        <Button type="primary" icon={<UploadOutlined/>} style={{backgroundColor: 'green'}}>Выбрать
                            файл</Button>
                    </Upload>
                    <Form.Item style={{width: '100%'}} name={'comment'}>
                        <Input placeholder={"Введите описание..."}/>
                    </Form.Item>
                    <Button type="primary" htmlType={'submit'} icon={<SaveOutlined/>}/>
                </Space.Compact>
            </Form>
            <div style={{display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '15px'}}>
                {files.map(f => <div key={f.id}>
                    <FileCard file={f} onDelete={() => onDelete(f.id)}/>
                </div>)}
            </div>
        </>
    )
}