import {useEffect, useState} from "react";
import axios from "axios";
import {Button, Col, Form, Input, Row, Select} from "antd";
import TextArea from "antd/lib/input/TextArea";
import {useProject} from "./ProjectState";

export function ProjectAdd({adder, dt}) {
    let {addProject} = useProject()
    const [data, setData] = useState({});
    useEffect(() => {
        axios.get('/pmdata').then(res => setData(res.data))
    }, []);

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            <Form
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                layout="vertical"
                onFinish={addProject}
                onFinishFailed={onFinishFailed}
            >
                <fieldset>
                    <legend>Основное информация</legend>
                    <Form.Item label="Название проекта" name="project_name"
                               rules={[
                                   {
                                       required: true,
                                       message: 'Название проекта!',
                                   },
                               ]}>
                        <Input placeholder={'Название проекта'}/>
                    </Form.Item>
                    <Row gutter={15}>
                        <Col span={12}>
                            <Form.Item label="Стартовая дата" name="start_date"
                                       rules={[
                                           {
                                               required: true,
                                           },
                                       ]}>
                                <Input type={'date'} placeholder={'Стартовая дата'}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Срок выполнение" name="deadline">
                                <Input type={'date'} placeholder={'Срок '}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item label="Подробная информация" name="desc">
                        <TextArea placeholder={'Подробная информация'}/>
                    </Form.Item>

                    <Row gutter={15}>
                        <Col span={12}>
                            <Form.Item label="Первичная цена договора" name="project_budget">
                                <Input placeholder={'Первичная цена договора'}/>
                            </Form.Item>
                        </Col>
                    </Row>

                </fieldset>

                <fieldset>
                    <legend>Зависымое параметры</legend>


                    <Row gutter={15}>
                        <Col span={12}>
                            <Form.Item label="Категория" name='service_id'>
                                <Select>
                                    {data.services?.map(item => <Select.Option key={item.id}
                                                                                    value={item.id}>{item.name}</Select.Option>)}
                                </Select>
                            </Form.Item>

                        </Col>
                        <Col span={12}>
                            <Form.Item label="Область" name='region_id'>
                                <Select>
                                    {data.regions?.map(item => <Select.Option key={item.id}
                                                                               value={item.id}>{item.nameru}</Select.Option>)}
                                </Select>
                            </Form.Item>

                        </Col>

                    </Row>
                    <Form.Item label="Ответственный" name='team_id'>
                        <Select>
                            {data.employees?.map(item => <Select.Option key={item.id}
                                                                    value={item.xodim_id}>{item.lastname} {item.firstname}</Select.Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Клиент" name='client_id'>
                        <Select>
                            {data.leads?.map(item => <Select.Option key={item.id}
                                                                        value={item.id}>{item.client_name}</Select.Option>)}
                        </Select>
                    </Form.Item>
                </fieldset>

                <Form.Item>
                    <Button type={'primary'} htmlType="submit">Добавить</Button>
                </Form.Item>
            </Form>
        </>
    )
}