import {Button, Col, Form, Input, message, Row, Select, Space, Steps} from "antd";
import {useEffect, useState} from "react";
import axios from "axios";
import {useAuthHeader} from "react-auth-kit";


export const RegisterBussines = () => {
    // `id`, `name`, `admin_id`, `guvohnoma`, `reg_date`, `yunalish_id`, `bussiness_type_id`, `viloyat_id`, `tuman_id`, `stir`, `address`, `post_index`, `phone`, `email`, `valyuta_id`, `tax_type_id`, `banks_id`, `bank_hr`, `start_date`, `ish_kuni_id`, `created_at`, `updated_at`
    const [current, setCurrent] = useState(0);
    let [regData, setRegData] = useState({btype: [], banks: [], taxes: []});
    const authHeader = useAuthHeader()
    axios.defaults.baseURL = process.env.REACT_APP_URL;
    axios.defaults.headers.common['Authorization'] = authHeader();
    axios.defaults.headers.post['Content-Type'] = 'application/json';

    useEffect(() => {
        axios.get('/regdata').then(data => setRegData(data.data))
    }, []);
    const next = () => {
        setCurrent(current + 1);
    };
    const prev = () => {
        setCurrent(current - 1);
    };

    const onFinish = (values) => {
        console.log(values)
        axios.post('/register', values).then(data => {
            message.success("Ro'yxatdan o'tdingiz. Iltimos Login parolni terib tizimga kiring")
            // tab('1')
        })
    }
    return (
        <>
            <Form
                name="register"
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
            >

                <fieldset>
                    <legend>Общая сведение</legend>
                    <Row gutter={15}>
                        <Col span={8}>
                            <Form.Item
                                label="Название организации"
                                name="name"
                                rules={[{required: true, message: 'Please input your username!'}]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Тип организации"
                                name="btype_id"
                                rules={[{required: true, message: 'Please input your username!'}]}
                            >
                                <Select
                                    options={regData.btype?.map(item => {
                                        return {value: item.id, label: item.name}
                                    })}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="ИНН"
                                name="stir"
                                rules={[{required: true, message: 'Please input your username!'}]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={15}>
                        <Col span={12}>
                            <Form.Item
                                label="Банк"
                                name="bank_id"
                                rules={[{required: true, message: 'Please input your username!'}]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Выберите банк"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={regData.banks?.map(bank => {
                                        return {value: bank.id, label: bank.mfo + ' - ' + bank.name}
                                    })}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Расчетный счет"
                                name="account_number"
                                rules={[{required: true, message: 'Please input your username!'}]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Row>
                </fieldset>

                <fieldset>
                    <legend>Manzillari</legend>
                    <Row gutter={15}>
                        <Col span={8}>
                            <Form.Item
                                label="Регион"
                                name="region_id"
                                rules={[{required: true, message: 'Please input your username!'}]}
                            >
                                <Select

                                    options={regData.regions?.map(item => {
                                        return {value: item.id, label: item.nameuz}
                                    })}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={16}>
                            <Form.Item
                                label="Адресс"
                                name="address"
                                rules={[{required: true, message: 'Please input your username!'}]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>


                    </Row>
                    <Row gutter={15}>
                        <Col span={8}>
                            <Form.Item
                                label="Эмаил"
                                name="email"
                                rules={[{required: false, message: 'Please input your username!'}]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Телефон"
                                name="tel"
                                rules={[{required: true, message: 'Please input your username!'}]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Тип налога"
                                name="tax_id"
                                rules={[{required: true, message: 'Please input your username!'}]}
                            >
                                <Select

                                    options={regData.taxes?.map(item => {
                                        return {value: item.id, label: item.type + ' - ' + item.percent + '%'}
                                    })}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </fieldset>
                <fieldset>
                    <legend>Administrator ma'lumotlari</legend>
                    <Row gutter={15}>
                        <Col span={8}>
                            <Form.Item
                                label="Логин администратора"
                                name="username"
                                rules={[{required: true, message: 'Please input your username!'}]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>


                        <Col span={8}>
                            <Form.Item
                                label="Пароль администратора"
                                name="password"
                                rules={[{required: true, message: 'Please input your username!'}]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Row>
                </fieldset>
                <Button htmlType={'submit'} type="primary"
                        onClick={() => message.success('Processing complete!')}>
                    Зарегистрировать
                </Button>

            </Form>
        </>
    )
}