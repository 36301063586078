import Board from "kanban-board-from-react-trello";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BoardCard } from "./BoardCard";
import { useTasks } from "./TasksState";
import { SingleTask } from "./SingleTask";
import { useSocket } from "../../../SocketStore";

export function PTaskBoard() {
    let params = useParams();
    let [rend, setRend] = useState(false)
    let { sendMessage, message, ws, connect } = useSocket()
    let {
        boardData,
        modal,
        setData,
        setModal,
        onCardMoveAcrossLanes
    } = useTasks();

    useEffect(() => {
        setData(params)
        if (connect) {
            ws.onmessage = (e) => {
                setRend(!rend)
            }
        }
    }, [message, rend]);

    return (
        <>
            <SingleTask modal={modal} setModal={setModal} />
            <Board
                style={{ backgroundImage: 'url(https://wallpaperaccess.com/full/212469.jpg)', backgroundSize: 'cover' }}
                data={boardData}
                lang={'ru'}
                components={{ Card: (card) => <BoardCard setModal={setModal} card={card} /> }}
                onCardMoveAcrossLanes={(fromLaneId, toLaneId, cardId, index) => {
                    onCardMoveAcrossLanes(fromLaneId, toLaneId, cardId, index, rend, setRend)
                    sendMessage({ type: 'task', data: { id: cardId } })
                }}
            />
        </>
    )
}


