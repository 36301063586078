import {Gauge} from '@ant-design/plots';
import {useParams} from "react-router-dom";
import axios from "axios";
import {useEffect, useState} from "react";
import Executes from '../Executes/Executes';

export function PDash() {
    let [prj, setPrj] = useState({})
    let params = useParams();

    useEffect(()=>{
        axios.get('project/' + params.id).then(data => {
            setPrj(data.data)
        })
    }, [])
let qoldiqkun = new Date(prj.deadline).getTime() - new Date().getTime()
    const config = {
        percent: prj.completion_percent / 100,
        range: {
            color: 'l(0) 0:#B8E1FF 1:#3D76DD',
        },
        startAngle: Math.PI,
        endAngle: 2 * Math.PI,
        indicator: null,
        statistic: {
            title: {
                offsetY: -36,
                style: {
                    fontSize: '36px',
                    color: '#4B535E',
                },
                formatter: () => prj.completion_percent + '%',
            },
            content: {
                style: {
                    fontSize: '24px',
                    lineHeight: '44px',
                    color: '#4B535E',
                },
                formatter: () => `Осталось ${(qoldiqkun / 1000 / 3600 / 24).toFixed()} дней`,
            },
        },
    };
    return (
        <>
            <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', width: '100%', backgroundColor: '#fff', padding: '25px', borderRadius: '15px', gap: '25px'}}>
                <div style={{border: '1px solid silver', borderRadius: '10px', padding: '15px'}}>
                    <h2>{prj.project_name}</h2>
                    <h2 style={{color: 'red'}}>{prj.project_budget ?? 0} сум</h2>
                    <p>{prj.description}</p>
                    <hr/>
                    <h3 style={{backgroundColor: prj.pstatuscolor}}>Статус: {prj.pstatusname}</h3>
                    <h4>Начало: {prj.start_date}</h4>
                    <h4>Окончание: {prj?.deadline}</h4>

                </div>
                <div style={{border: '1px solid silver', borderRadius: '10px', padding: '15px'}}>
                    <Gauge width={'100%'} {...config} />
                </div>
                <div style={{border: '1px solid silver', borderRadius: '10px', padding: '15px'}}>
                    <h3>Клиент: {prj.client_name}</h3>
                    <h5>Проект создан: {prj.created_at}</h5>
                    <h3>Тип проекта: {prj.service_name ?? "Не указано"}</h3>
                    <h4>Ответственный: {prj.team_name ?? "Не указано"} {prj.team_lastname}</h4>

                </div>
            </div>
            <Executes />
        </>
    )
}