import {MainTable} from "../../../Components/MainTable/MainTable";
import {useEffect, useState} from "react";
import axios from "axios";
import {useParams} from "react-router-dom";
import {PVexaAdd} from "./PVexaAdd";
import {PVexaEdit} from "./PVexaEdit";
import {message} from "antd/lib";
import {Progress} from "antd";

export function Pvexa() {
    let params = useParams();
    let [editData, setEditData] = useState([]);
    let [vexa, setVexa] = useState([]);
    let [rend, setRend] = useState(false);

    useEffect(() => {
        axios.get('/taskgroups/' + params.id).then(res => setVexa(res.data))
    }, [rend]);

    let columns = [
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
            width: 250
        },
        {
            title: 'Стартовая дата',
            dataIndex: 'start',
            key: 'start',
            width: 180
        },
        {
            title: 'Дата окончание',
            dataIndex: 'end',
            key: 'end',
            width: 180
        },
        {
            title: 'Прогресс выполнение',
            dataIndex: 'progress',
            key: 'progress',
            render: (_, rec)=> <Progress size={'large'} percent={rec.progress} />
        },

    ]
    let onDelete = (e, rec) => {
        axios.delete('/taskgroups/' + rec.id).then(res => {
            if (res.status === 200) {
                message.success(`Ma'lumot muvaffaqiyatli O'chirildi`);
                setRend(!rend)
            }
        })
    }
    let onEdit = (rec) => {
        setEditData(rec)
        setRend(!rend)
    }
    return (
        <>
            <MainTable
                pdata={vexa}
                pcolumns={columns}
                pageTitle={'Группа Задач'}
                add={<PVexaAdd rend={rend} setRend={setRend}/>}
                edit={<PVexaEdit rend={rend} setRend={setRend} iv={editData}/>}
                onEdit={onEdit}
                setEd={setEditData}
                defkey={'id'}
                onDelete={onDelete}
                xwidth={1000}
            />
        </>
    )
}