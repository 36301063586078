import {MainTable} from "../../../Components/MainTable/MainTable";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import {message} from "antd/lib";
import {PTaskAdd} from "./PTaskAdd";
import {PTaskEdit} from "./PTaskEdit";
import {Progress} from "antd";

export function PTasks() {

    let params = useParams();
    // console.log(params)
    const [editData, setEditData] = useState([]);
    let [task, setTask] = useState([]);
    let [rend, setRend] = useState(false);

    useEffect(() => {
        axios.get('/tasks/' + params.id).then(data => setTask(data.data))
    }, [rend]);
    let columns = [
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Состояние',
            dataIndex: 'ptask_status_id',
            key: 'ptask_status_id',
        },
        {
            title: 'Группа Задач',
            dataIndex: 'task_group_name',
            key: 'task_group_name',
        },
        {
            title: 'Начало',
            dataIndex: 'start',
            key: 'start',
        },
        {
            title: 'Окончание',
            dataIndex: 'end',
            key: 'end',
        },
        {
            title: 'Прогресс',
            dataIndex: 'progress',
            key: 'progress',
            render: (_, rec) => <Progress percent={rec.progress} />
        },

        {
            title: 'Заметки',
            dataIndex: 'description',
            key: 'description',
        },

    ]
    let onDelete = (e, rec) => {
         axios.delete('/tasks/' + rec.id).then(resData => {
            if(resData.status === 200){
                message.success(`Ma'lumot muvaffaqiyatli O'chirildi`);
                setRend(!rend)
            }
        })
    }
    let onEdit = (rec) => {
        setEditData(rec)
        setRend(!rend)
    }


    return (
        <>
            <MainTable
                pdata={task}
                pcolumns={columns}
                pageTitle={'Задачы'}
                add={<PTaskAdd adder={setRend} dt={rend}/>}
                edit={<PTaskEdit rend={rend} adder={setRend} iv={editData}/>}
                onEdit={onEdit}
                setEd={setEditData}
                defkey={'id'}
                onDelete={onDelete}
            />
        </>
    )
}