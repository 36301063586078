import {create} from "zustand";
import axios from "axios";
import {Select} from "antd";
import {message} from "antd/lib";

export let useLeads = create((set, get) => ({
    columns: [
        {
            title: 'ФИО',
            dataIndex: 'client_name',
            key: 'client_name',
        },

        {
            title: 'Компания',
            dataIndex: 'company_name',
            key: 'company_name',
        },
        {
            title: 'Телефон',
            dataIndex: 'mobile',
            key: 'mobile',
        },
        {
            title: 'Номер офиса',
            dataIndex: 'cell',
            key: 'cell',
        },
        {
            title: 'Область',
            dataIndex: 'region',
            key: 'region',
        },
        {
            title: 'Адресс',
            dataIndex: 'address',
            key: 'address',
        },

    ],
    ed: {},
    leads: [],
    setEd: (data) => {
        set({ed: data})
    },
    setLeads: () => {
        axios.get('leads').then(res => set({leads: res.data}))
    },
    addLead: (values) => {
        axios.post('leads', values).then(data => {
            if (data.status === 200) {
                get().setLeads()
            }
        })
    },
    editLead: (values) => {
        axios.put('leads/' + values.id, values).then(data => {
            if (data.status === 200) {
                message.success("Изменено")
                get().setLeads()
            }
        })
    },
    onEdit: (rec) => {
        set({ed: rec})
    },
    onDelete: (_, rec) => {
        axios.delete('/leads/' + rec.id).then(res => {
            (res.status === 200) ? message.success("Удалено") : message.error("Ошибка при удалении")
            get().setLeads()
        })
    }
}))