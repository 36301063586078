import { Button, DatePicker, Form, Input, Table } from 'antd';
import React from 'react';
import { useSalary } from './SalaryStore';

function Add() {
    let { createSalary, setYear, setMonth } = useSalary();
    return (
        <>
            <Form
                name="basic"
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={(v)=>{
                    let y = v.date.split('-')[0]
                    let m = v.date.split('-')[1]
                    m = parseInt(m) - 1
                    let data = {year: y, month: m}
                    setMonth(m)
                    setYear(y)
                    console.log(data);
                    createSalary(data)
                }}
                autoComplete="off"
            >
                <Form.Item
                    label="Выберите месяц и год"
                    name="date"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your username!',
                        },
                    ]}
                >
                    <Input type="month" width={'100%'} />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Сформировать
                    </Button>
                </Form.Item>
            </Form>


        </>
    );
}

export default Add;