import {MainTable} from "../../../Components/MainTable/MainTable";
import {Add} from "./Add";
import {useEffect, useState} from "react";
import axios from "axios";
import {Edit} from "./Edit";

export const Pstatus = () => {
    let [data, setData] = useState([])
    let [rend, setRend] = useState(true)
    let [ed, setEd] = useState({})
    useEffect(() => {
        axios.get('pmtaskstatus').then(res => setData(res.data))
    }, [rend]);

    let onDelete = (_, rec) => {
        // console.log(id)
        axios.delete('pmtaskstatus/' + rec.id).then(res => {
                if (res.status === 200) {
                    setRend(!rend)
                }
            }
        )
    }

    let onEdit = (rec)=>{
        setEd(rec)
    }
    return (
        <>
            <MainTable
                xwidth={1000}
                onDelete={onDelete}
                defkey={'id'}
                pcolumns={[
                    {
                        title: 'Название этапа',
                        dataIndex: 'name',
                        key: 'name',
                    },
                    {
                        title: 'Порядковая место',
                        dataIndex: 'order',
                        key: 'order',
                    },
                ]}
                pageTitle={"Этапы выполнение проектов"}
                pdata={data}
                edit={<Edit ed={ed} rend={rend} setRend={setRend} />}
                add={<Add setRend={setRend} rend={rend}/>}
                onEdit={onEdit}
                setEd={setEd}
            />
        </>
    )
}