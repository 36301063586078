import {Button, Col, Form, Input, message, Row, Select, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";
import axios from "axios";
import {useEmployee} from "./EmployeeState";

export function EditEmploye() {
    let {editData, setEmployees} = useEmployee()
    const [data, setEData] = useState({});
    const [userImg, setUserImg] = useState(process.env.REACT_APP_MAINURL + '/upload/user/defuser.png');
    useEffect(() => {
        axios('/regdata').then(datar => {
            setEData(datar.data)
        })
    }, []);


    const props = {
        name: 'image',
        action: process.env.REACT_APP_URL + '/file',
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file.response, info.fileList);
                setUserImg(process.env.REACT_APP_MAINURL + info.file.response.data.image)
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} Fayl Serverga muvaffaqiyatli yuklandi`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} Faylni yuklashda xatolik.`);
            }
        },
    };


    const onFinish = (values) => {
        values.img = userImg
        values.users_id = editData.users_id;
        values.bussiness_id = editData.bussiness_id
        axios.put('/employees/' + values.id, values).then(resData => {
            if(resData.status === 200){
                setEmployees()
            }
        })

    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const [form] = Form.useForm()
    useEffect(() => {
        form.setFieldsValue(editData)
    }, [editData])


    return (
        <>
            <Form
                form={form}
                name="editform"
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item label="ID" name="id">
                    <Input/>
                </Form.Item>
                <fieldset>
                    <legend>Основная информация</legend>
                    <Row gutter={15}>
                        <Col span={12}>
                            <Form.Item label="Фамилия" name="lastname"
                                       rules={[
                                           {
                                               required: true,
                                               message: 'Заполните поле!',
                                           },
                                       ]}>
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Имя" name="firstname"
                                       rules={[
                                           {
                                               required: true,
                                               message: 'Заполните поле!',
                                           },
                                       ]}>
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Отчество" name="surname">
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="ПИНФЛ" name="jshshir">
                                <Input placeholder={'305......'}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item label="Загрузить изображению" name={'user_img'} valuePropName="user_img">
                        <Upload {...props}>
                            <Button icon={<UploadOutlined/>}>Выбрать</Button>
                        </Upload>
                    </Form.Item>
                </fieldset>

                <fieldset>
                    <legend>Контакты</legend>

                    <Row gutter={15}>
                        <Col span={8}>
                            <Form.Item label="Телефон" name="phone">
                                <Input type='phone' placeholder={'+998---------'}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Область" name='viloyat_id'>
                                <Select>
                                    {data.regions?.map(item => <Select.Option key={item.id}
                                                                              value={item.id}>{item.nameru}</Select.Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item label="Аддресс" name='address'>
                        <Input/>
                    </Form.Item>
                </fieldset>

                <fieldset>
                    <legend>Данные для доступа систему</legend>

                    <Row gutter={15}>
                        <Col span={12}>
                            <Form.Item label="Логин" name="name">
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Email" name="email">
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Пароль" name="password">
                                <Input.Password/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Рол или дольжность" name="role_id">
                                <Select showSearch>
                                    {data.roles?.map(item => <Select.Option key={item.id}
                                                                            value={item.id}>{item.role}</Select.Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                </fieldset>


                <Form.Item>
                    <Button htmlType="submit">Обновить</Button>
                </Form.Item>
            </Form>
        </>
    )
}