import {Button, Form, Image, Input, message, Upload} from "antd";
import {useServiceTypes} from "./ServiseState";
import {useState} from "react";
import {UploadFile} from "../../Components/Upload/UploadFile";

export const Add = () => {
    let [image, setImage] = useState('')
    let {onAdd, onFinishFailed} = useServiceTypes()


    return (
        <>
            <Form
                name="basic"
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={(values)=>onAdd({img: image, ...values})}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="Название"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your username!',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Картинка"
                >
                   <UploadFile setFile={setImage} />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Добавить
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}