import {create} from "zustand";
import axios from "axios";
import {message} from "antd";

export let useEmployee = create((set, get) => ({
    employees: [],
    editData: {},
    setEditData: (data) => {
        data.password = ''
        set({editData: data})
    },
    setEmployees: () => {
        axios.get('/employees').then(res => set({employees: res.data}))
    },
    columns: [
        {
            title: 'Картинка',
            dataIndex: 'img',
            key: 'img',
            render: (img) => <div style={{
                border: '1px solid silver',
                width: '50px',
                height: '50px',
                borderRadius: '50px',
                backgroundImage: 'url(' + img + ')',
                backgroundSize: 'cover'
            }}></div>
        },
        {
            title: 'Имя',
            dataIndex: 'firstname',
            key: 'firstname',
        },
        {
            title: 'Фамилия',
            dataIndex: 'lastname',
            key: 'lastname',
        },
        {
            title: 'Отчество',
            dataIndex: 'surname',
            key: 'surname',
        },

        {
            title: 'ПИНФЛ',
            dataIndex: 'jshshir',
            key: 'jshshir',
        },
        {
            title: 'Телефон',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Рол или Дольжность',
            dataIndex: 'role_name',
            key: 'role_name',
        },
        {
            title: 'Область',
            dataIndex: 'region',
            key: 'region',
        },
        {
            title: 'Аддресс',
            dataIndex: 'address',
            key: 'address',
        },
    ],
    onDelete: (_, rec) => {
        axios.delete('/employees/' + rec.id, rec.xodim_id).then(resData => {
            if (resData.status === 200) {
                message.success(`Успешно удалено`);
                get().setEmployees()
            }
        })
    },
    onEdit: (rec) => {
        get().setEditData(rec)
    },

}))