import {Avatar, Badge, Button, Drawer, Form, Input, List, message, Space, Upload} from "antd";
import {FileAddOutlined, NotificationOutlined, SendOutlined} from "@ant-design/icons";
import {useContext, useEffect, useRef, useState} from "react";
import axios from "axios";

import {useSocContext} from "../../Providers/SocketProvider";
import {useForm} from "antd/es/form/Form";

export function Notific({ws}) {
    let [messages, setMessages] = useState([])
    let [rend, setRend] = useState(true)
    const [open, setOpen] = useState(false);
    const [userImg, setUserImg] = useState('');
    let [notifForm] = useForm();

    const props = {
        name: 'image',
        action: process.env.REACT_APP_URL + '/file',
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                setUserImg(process.env.REACT_APP_MAINURL + info.file.response.data.image)
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };
    const context = useSocContext();

    useEffect(() => {
        // axios.get('chat').then(res => setMessages(res.data))
    }, [rend, context]);
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    const onFinish = (values) => {
        values.file = userImg
        axios.post('setmess', values).then(res => {
            if (res.status === 200) {
                setRend(!rend)
                setUserImg('')
                console.log(ws)
                ws.send(JSON.stringify({type: 'notif', message: 'Sizga yangi ogohlantirish mavjud'}))
                notifForm.resetFields()
            }
        })
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    return (
        <>
            <Badge count={'N'} size={'small'} offset={[-1, 3]}>
                <Avatar size="small" style={{backgroundColor: 'orange'}} onClick={showDrawer}
                        icon={<NotificationOutlined/>}/>
            </Badge>
            <Drawer style={{ backgroundColor: '#f5f5f5',}} title="Уведомлении и ЧАТ" width={'500px'} placement="right" onClose={onClose} open={open}>

                <div className="chat" style={{
                    height: '100%',
                    display: 'grid',
                    gridTemplateColumns: '1fr',
                    gridTemplateRows: '1fr 50px',

                }}>
                    <div className="messages" style={{overflow: 'auto', height: '100%'}}>
                        <div style={{gap: '10px', display: 'flex', justifyContent: 'end', flexDirection: 'column'}}>
                            {messages.sort().map(m => {
                                return (
                                    <div className="message" key={m.id}
                                         style={{
                                             border: '1px solid silver',
                                             borderRadius: '10px',
                                             padding: '10px',
                                             marginBottom: '3px',
                                             backgroundColor: '#fff'
                                         }}>
                                        <h4>{m.lastname} {m.firstname}</h4>
                                        <p style={{margin: '0'}}>{m.message}</p>
                                        <a target={'_blank'} href={m.file}>{m.file}</a>
                                        <p style={{textAlign: 'end', margin: '0'}}><b>{m.created_at}</b></p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="chatform">
                        <Form
                            form={notifForm}
                            name="basic"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                        >
                            <Form.Item
                                name="message"
                                wrapperCol={{
                                    span: 24,
                                }}>
                                <Input
                                    prefix={(
                                        <Upload {...props}>
                                            <Button style={{backgroundColor: 'green'}} type={'primary'}
                                                    icon={<FileAddOutlined/>}/>
                                        </Upload>
                                    )}
                                    suffix={<Button icon={<SendOutlined/>} type="primary" htmlType="submit"/>}
                                />
                            </Form.Item>
                        </Form>

                    </div>
                </div>
            </Drawer>
        </>
    )
}