import { Descriptions, Image } from 'antd';
import React from 'react';
import EmMuster from './EmMuster';
import Kelishi from './Kelishi';

function EmData({ user }) {
    console.log(user);

    /*
    
    bank_card : null
    bank_hr : null
    birthday : null
    jins_id : null
    malumot_id : null
    post_index : null
    role_name : "Ishchi"
    stir : null
    updated_at : null
    */


    return (
        <>
            <h3>Основная информация</h3>
            <div style={{ display: 'flex', gap: '15px', marginBottom: '15px' }}>
                <Image src={user.img} style={{ width: '120px', height: '160px', objectFit: 'cover' }} />
                <Descriptions layout='vertical' bordered style={{ width: '100%' }} size='small'>
                    <Descriptions.Item label="ФИО">{user.firstname} {user.lastname} {user.surname}</Descriptions.Item>
                    <Descriptions.Item label="ИНПС">{user.jshshir}</Descriptions.Item>
                    <Descriptions.Item label="Телефон">{user.phone}</Descriptions.Item>
                    <Descriptions.Item label="Email">{user.email}</Descriptions.Item>
                </Descriptions>

            </div>
            <div>
                <h3>Адреса</h3>
                <Descriptions layout='vertical' bordered style={{ width: '100%' }} size='small'>
                    <Descriptions.Item label="Область">{user.region}</Descriptions.Item>
                    <Descriptions.Item label="Адресс">{user.address}</Descriptions.Item>
                </Descriptions>
            </div>
            <div>
                <h3>Табель за {new Date().getFullYear()}</h3>
                <Kelishi user={user} />
            </div>
        </>
    );
}

export default EmData;