import css from './Login.module.css'
import {Button, Form, Input, message} from "antd";
import {useSignIn} from "react-auth-kit";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import logo from './../../assets/mayaknewlogo.png'
import {Drawer} from "antd/lib";
import {useState} from "react";
import {RegisterBussines} from "./RegisterBussines";

export default function LoginLayout() {
    const signIn = useSignIn()
    let [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    let url = process.env.REACT_APP_URL + '/login';

    const onFinish = (values) => {

        axios.post(url, values).then(data => {
            if (signIn(
                {
                    token: data.data.access_token,
                    expiresIn: data.data.expires_in,
                    tokenType: data.data.token_type,
                    authState: data.data.user,
                }
            )) {
                navigate("/main");
            } else {
                navigate("/login");
                messageApi.open({
                    type: 'error',
                    content: <>
                        <h3>Что то произошло не так. Проверяйте введенные данные!!!</h3>
                        <p>{data.message}</p>
                    </>,
                });
            }
        }).catch(data => {
            messageApi.open({
                type: 'error',
                content: <>
                    <h3>Что то произошло не так. <br/>Проверяйте введенные данные!!!</h3>
                    <p>{data.message}</p>
                </>,
            });
        })
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };
    return (
        <div className={css.LoginLayout}>
            {contextHolder}
            <div className={css.formBlock}>
                <img src={logo} style={{height: '150px'}} alt="Mayak media market"/>
                <h3>MAYAK MEDIA MARKET</h3>
                <Form
                    name="basic"
                    labelCol={{
                        span: 24,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    style={{width: '100%'}}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="on"
                >
                    <Form.Item
                        label="Эмаил"
                        name="login"
                        rules={[
                            {
                                required: true,
                                message: "Пожалуюста введите эмаил",
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label="Пароль"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: "Пожалуюста введите пароль",
                            },
                        ]}
                    >
                        <Input.Password/>
                    </Form.Item>

                    <Form.Item>
                        <Button style={{marginTop: '25px'}} type="primary" htmlType="submit">
                            ВХОД
                        </Button>
                    </Form.Item>
                </Form>
            </div>
            <Button onClick={()=>setOpen(true)} style={{position: 'fixed', bottom: '50px', right: '50px'}}>Yangi biznesni ro'yxatdan o'tkazish</Button>
            <Drawer width={1200} title="Yangi biznesni ro'yxatdan o'tkazish" onClose={()=>setOpen(false)} open={open}>
                <RegisterBussines />
            </Drawer>
        </div>
    )
}
