import {MainTable} from "../../Components/MainTable/MainTable";
import {useEffect, useState} from "react";
import axios from "axios";
import {Drawer, Select} from "antd";
import {LeadAdd} from "./LeadAdd";
import {LeadEdit} from "./LeadEdit";
import LeadCard from "./LeadCard";
import {useLeads} from "./LeadState";

export const LeadTable = () => {

    let {leads, setLeads, columns, onEdit, onDelete} = useLeads();

    const [data, setData] = useState([]);
    let [rend, setRend] = useState(false)
    let [statusColor, setStatusColor] = useState('white')
    let [stdata, setStData] = useState({})
    let [ed, setEd] = useState({})
    let [cardopen, setCardOpen] = useState(false)

    useEffect(() => {
        setLeads()
    }, []);

    return (
        <>
            <MainTable
                add={<LeadAdd />}
                pdata={leads}
                pcolumns={columns}
                pageTitle={"Заказщики"}
                defkey={'id'}
                xwidth={1500}
                onDelete={onDelete}
                edit={<LeadEdit/>}
                setEd={setEd}
                onEdit={onEdit}
            />
        </>
    )
}