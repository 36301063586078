import { Card, Col, Progress, Row, Statistic, Table } from "antd";
import { ApartmentOutlined,  ReconciliationOutlined, TeamOutlined } from "@ant-design/icons";
import { Line, Pie } from '@ant-design/plots';
import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

export function Dashboard() {
    let [stat, setStat] = useState({
        emps: [],
        leads: [],
        projects: [],
        ptypes: []
    })

    let [data, setData] = useState([])

    let [data2, setData2] = useState([])

    useEffect(() => {
        axios.get('dash').then(res => {
            // console.log(res.data);
            setStat(res.data)
            setData2(res.data.ptypes.map(it => ({
                type: it.name,
                // color: it.color,
                value: res.data.projects.filter(p => p.service_id === it.id).length,
            })))

            let pgraf = res.data.projects.map(it => ({
                year: new Date(it.created_at).getMonth(),
                value: it.project_name
            }))

            pgraf = pgraf.reduce((acc, it) => {
                let idx = acc.findIndex(i => i.year === it.year)
                if (idx === -1) {
                    acc.push({
                        year: it.year,
                        value: 1
                    })
                } else {
                    acc[idx].value.push(it.value + 1)
                }
                return acc
            }, [])

            setData(pgraf)
        })
    }, [])


    const config = {
        data,
        yField: 'value',
        xField: 'year',
        tooltip: {
            customContent: (title, items) => {
                console.log(title, items);
                
                return (
                    <div key={title}>
                        <h5 style={{ marginTop: 16 }}>{title}</h5>
                        <ul style={{ paddingLeft: 0 }}>
                            {items?.map((item, index) => {
                                const { name, value, color } = item;
                                return (
                                    <li
                                        key={item.year}
                                        className="g2-tooltip-list-item"
                                        data-index={index}
                                        style={{ marginBottom: 4, display: 'flex', alignItems: 'center' }}
                                    >
                                        <span className="g2-tooltip-marker" style={{ backgroundColor: color }}></span>
                                        <span
                                            style={{ display: 'inline-flex', flex: 1, justifyContent: 'space-between' }}
                                        >
                                            <span style={{ marginRight: 16 }}>{name}:</span>
                                            <span className="g2-tooltip-list-item-value">{value}</span>
                                        </span>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                );
            },
        },
        point: {
            size: 5,
            shape: 'diamond',
            style: {
                fill: 'white',
                stroke: '#2593fc',
                xField: 'Date',
                yField: 'scales',
                lineWidth: 2,
            },
        },
    };


    const config2 = {
        appendPadding: 10,
        data: data2,
        angleField: 'value',
        colorField: 'type',
        radius: 0.75,
        color: ['teal', 'pink', 'aqua', 'green', 'blue', 'red'],
        label: {
            type: 'spider',
            labelHeight: 28,
            content: '{name}\n{percentage}',
        },
        interactions: [
            {
                type: 'element-selected',
            },
            {
                type: 'element-active',
            },
        ],
    };
    let topParam = [
        {
            id: 1,
            title: 'ЗАКАЗЩИКИ',
            value: stat.leads.length,
            precision: 0,
            color: 'green',
            icon: <ReconciliationOutlined />,
            suffix: 'организации'
        },
        {
            id: 2,
            title: 'ПРОЕКТЫ',
            value: stat.projects.length,
            precision: 0,
            color: 'indigo',
            icon: <ApartmentOutlined />,
            suffix: 'штук'
        },
        {
            id: 3,
            title: 'СОТРУДНИКИ',
            value: stat.emps.length,
            precision: 0,
            color: 'blue',
            icon: <TeamOutlined />,
            suffix: 'персона'
        }
    ]
    return (
        <>
            <h1>Показателы</h1>
            <Row gutter={10}>
                {topParam.map(it => {
                    return (
                        <Col style={{ marginBottom: '10px' }} key={it.id} md={{ span: 12 }} lg={{ span: 8 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                            <Card bordered={false}>
                                <Statistic
                                    title={<b>{it.title}</b>}
                                    value={it.value}
                                    precision={it.precision}
                                    valueStyle={{
                                        color: it.color,
                                    }}
                                    prefix={it.icon}
                                    suffix={it.suffix}
                                />
                            </Card>
                        </Col>
                    )
                })}
            </Row>
            <h1>График</h1>
            <Row gutter={10}>
                <Col lg={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }} md={{ span: 24 }} >
                    <Card>
                        <h4>Заказы</h4>
                        <Line {...config} />
                    </Card>
                </Col>
                <Col lg={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }} md={{ span: 24 }}>
                    <Card>
                        <h4>Заказщики</h4>
                        <Pie {...config2} />
                    </Card>
                </Col>
            </Row>
            <h1>Последные действии</h1>
            <Row gutter={10}>
                <Col lg={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }} md={{ span: 24 }} >
                    <Card>
                        <h4>Проекты</h4>
                        <Table columns={[
                            {
                                title: 'Название',
                                dataIndex: 'project_name',
                                key: 'project_name',
                                render: (text, record) => {
                                    return (
                                        <Link to={`/main/service/${record.id}`}>{text}</Link>
                                    )
                                }
                            },
                            {
                                title: 'Статус выполнение',
                                dataIndex: 'desc',
                                key: 'desc',
                                render: (text, record) => {
                                    return (
                                        <Progress percent={record.completion_percent} />
                                    )
                                }
                            },
                            {
                                title: 'Описание',
                                dataIndex: 'desc',
                                key: 'desc',
                            },
                            {
                                title: 'Создано',
                                dataIndex: 'created_at',
                                key: 'created_at',
                            },
                            {
                                title: 'Срок',
                                dataIndex: 'deadline',
                                key: 'deadline',
                            },
                        ]} dataSource={stat.projects} />
                    </Card>
                </Col>
                <Col lg={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }} md={{ span: 24 }} >
                    <Card>
                        <h4>Заказщики</h4>
                        <Table columns={[
                            {
                                title: 'Организация',
                                dataIndex: 'company_name',
                                key: 'company_name',
                            },
                            {
                                title: 'Представитель',
                                dataIndex: 'client_name',
                                key: 'client_name',
                            },
                            {
                                title: 'Телефон',
                                dataIndex: 'mobile',
                                key: 'mobile',
                            }
                        ]} dataSource={stat.leads} />
                    </Card>
                </Col>
                <Col lg={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }} md={{ span: 24 }} >
                    <Card>
                        <h4>Задачы</h4>
                        <Table columns={[
                            {
                                title: 'Название',
                                dataIndex: 'name',
                                key: 'name',
                            },
                            {
                                title: 'Описание',
                                dataIndex: 'description',
                                key: 'description',
                            },
                            {
                                title: 'Создано',
                                dataIndex: 'created_at',
                                key: 'created_at',
                            }
                        ]} dataSource={stat.projects} />
                    </Card>
                </Col>


            </Row>
        </>
    )
}