import { Button, Drawer, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { useExecutes } from './ExeStore';
import { useParams } from 'react-router-dom';
import { MainTable } from '../../../Components/MainTable/MainTable';
import { useForm } from 'antd/es/form/Form';
import axios from 'axios';

function Executes() {
    let { executes, getExec, columns, payModal, setPayModal, current, columnssumm } = useExecutes()
    let p = useParams()
    let [prbudget, setPrbudget] = useState(0)
    let [exeform] = useForm()
    let [summary, setSummary] = useState([])

    useEffect(() => {
        getExec(p.id)
        axios.get('prbuget/' + p.id).then(res => setPrbudget(res.data))
    }, [])
    let a = {
        "id": 5,
        "project_id": 5,
        "user_id": 4,
        "kpi": 0,
        "task_id": 9,
        "micro_task_id": null,
        "date": null,
        "comment": null,
        "paypercent": 0,
        "payment": 0,
        "firstname": "Bobur",
        "lastname": "Xamidov",
        "task_name": "\u041f\u0440\u043e\u0435\u043a\u0442"
    }

    useEffect(() => {
        const salaryAggregation = executes.reduce((acc, curr) => {
            if (acc[curr.user_id]) {
                acc[curr.user_id].kpi += curr.kpi;
            } else {
                acc[curr.user_id] = { ...curr };
            }
            return acc;
        }, {});
        const result = Object.values(salaryAggregation);
        setSummary(result);
    }, [executes])

    useEffect(() => {
        exeform.setFieldsValue(current)
    }, [current])

    let onFinish = (v) => {
        v.payment = prbudget.project_budget / 100 * v.paypercent
        axios.put('execs/' + v.id, v).then(res => {
            if (res.status === 200) {
                getExec(p.id)
            }
        })
    }

    return (
        <>
            <Drawer open={payModal} footer={false} title={"Добавить оплату"} onClose={() => setPayModal(false)}>
                <Form
                    labelCol={{ span: 24 }}
                    form={exeform}
                    onFinish={onFinish}
                >
                    <Form.Item name={'id'} label="ID" hidden={true}>
                        <Input />
                    </Form.Item>
                    <Form.Item name={'user_id'} label="ID" hidden={true}>
                        <Input />
                    </Form.Item>
                    <Form.Item name={'paypercent'} label="Процент к оплату">
                        <Input type='number' max={'100'} />
                    </Form.Item>
                    <Form.Item name={'comment'} label="Комментарий к оплату">
                        <Input placeholder='Ввведите комментарий к оплату' />
                    </Form.Item>

                    <Form.Item>
                        <Button htmlType='submit' type='primary'>Сохранить</Button>
                    </Form.Item>
                </Form>
            </Drawer>

            <MainTable
                pcolumns={columnssumm}
                pdata={summary}
                pageTitle={`КПИ сотрудников по проекту. Обшая сумма проекта ${prbudget.project_budget} сум`}
                defkey={'id'}
                add={false}
                footer={() => <h3>Выделенная сумма для зарплат: {executes.reduce((a, b) => (a + b.payment), 0)}</h3>}
            />


            <MainTable
                pcolumns={columns}
                pdata={executes}
                pageTitle={`КПИ сотрудников по задачам.`}
                defkey={'id'}
                add={false}
            />
        </>
    );
}

export default Executes;