import React from 'react';
import ReactDOM from 'react-dom/client';
import 'suneditor/dist/css/suneditor.min.css';
import './index.css';
import 'leaflet/dist/leaflet.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {AuthProvider} from "react-auth-kit";

Date.prototype.daysInMonth = function(month= '0') {
    return 33 - new Date(this.getFullYear(), String(month), 33).getDate();
};



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AuthProvider authType={'localstorage'} authName={'Bearer'}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </AuthProvider>
);


reportWebVitals();
