import {Button, Form, Input} from "antd";
import {useForm} from "antd/es/form/Form";
import {useEffect} from "react";
import axios from "axios";
import {message} from "antd/lib";

export const Edit = ({ed, rend, setRend}) => {

    let onFinish = (v) => {
        axios.put('pmtaskstatus/' + v.id, v).then(res=>{
            if(res.status === 200){
                setRend(!rend)
            }
        })
    }
    let onFinishFailed = (v) => {
        message.warning("Ma'lumotlarni to'ldirishda hatolik")
    }

    let [form] = useForm()
    useEffect(() => {
        form.setFieldsValue(ed)
    }, [ed]);
    return (
        <>
            <Form
                form={form}
                name="basic"
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >

                <Form.Item
                    name="id"
                    hidden={true}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Название этапа"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your username!',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>


                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Добавить
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}