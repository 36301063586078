import {useTasks} from "./TasksState";
import {useEffect, useState} from "react";
import {TaskDetails} from "./TaskDetails";
import { Modal } from "antd";

export const SingleTask = ({modal, setModal}) => {
    let [rend, setRend] = useState(false)
    let {metaData} = useTasks()
    useEffect(() => {
        setRend(!rend)
    }, [metaData]);
    return (
        <Modal open={modal} title={metaData.name} footer={false} onCancel={() => {
            setModal(false)
        }} width={"80vw"}>
            <div style={{
                padding: '15px',
                borderRadius: '10px',
                boxShadow: 'inset 0px 0px 5px gray'
            }}>
                <div>
                    <TaskDetails render={rend} pm_task_id={metaData.id}/>
                    {/* <TaskFiles render={rend} task_id ={data.id}/> */}
                </div>
            </div>
        </Modal>
    )
}