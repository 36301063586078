import React, {useState} from "react";
import {ViewMode, Gantt} from "gantt-task-react";
import {ViewSwitcher} from "./ViewSwitcher";
import {getStartEndDateForProject} from "./Helper";
import "gantt-task-react/dist/index.css";

export function GanttComponent({data}) {
    const [view, setView] = useState(ViewMode.Day);
    const [tasks, setTasks] = useState(data);
    const [isChecked, setIsChecked] = useState(true);

    // const handleTaskChange = (task) => {
    //     console.log("On date change Id:" + task.id);
    //     let newTasks = tasks.map(t => (t.id === task.id ? task : t));
    //     if (task.project) {
    //         const [start, end] = getStartEndDateForProject(newTasks, task.project);
    //         const project = newTasks[newTasks.findIndex(t => t.id === task.project)];
    //         if (
    //             project.start.getTime() !== start.getTime() ||
    //             project.end.getTime() !== end.getTime()
    //         ) {
    //             const changedProject = {...project, start, end};
    //             newTasks = newTasks.map(t =>
    //                 t.id === task.project ? changedProject : t
    //             );
    //         }
    //     }
    //     setTasks(newTasks);
    // };
    // console.log(data)
    return (
        <>
            <div className="Wrapper" style={{backgroundColor: '#fff', height: '100%', padding: '20px'}}>
                <ViewSwitcher
                    onViewModeChange={viewMode => setView(viewMode)}
                    onViewListChange={setIsChecked}
                    isChecked={isChecked}
                />
                <Gantt
                    tasks={data}
                    viewMode={view}
                    // onDateChange={handleTaskChange}
                />
            </div>
        </>
    )
}

