import LoginLayout from "./Layouts/LoginLayout/LoginLayout";
import { Route, Routes } from "react-router-dom";
import { RequireAuth, useIsAuthenticated, useSignOut } from "react-auth-kit";
import MainLayout from "./Layouts/MainLayout/MainLayout";
import { useAuthUser } from 'react-auth-kit'
import Home from "./Layouts/Home/Home";
import React, { useEffect, useRef, useState } from "react";
import { SocketProvider } from "./Providers/SocketProvider";
import sound from './assets/mixkit-doorbell-tone-2864.wav'
import { useSocket } from "./SocketStore";
import { ProjectManagerLayout } from "./Layouts/ProjectManager/ProjectManagerLayout";
import { ManufactoryLayout } from "./Layouts/ManufactureLayout/ManufactoryLayout";
import { ConfigProvider } from "antd";
import Director from "./Layouts/Director/Director";
import Hr from "./Layouts/Hr/Hr";
import Buxgalter from "./Layouts/Buxgalter/Buxgalter";


export const SoccetContext = React.createContext(null);
const App = () => {
    let audio = useRef();
    let [socmess, setSocmess] = useState({})
    let { onConnect, ws, connect,  setMessage } = useSocket()


    useEffect(() => {
        setSocmess(ws)
        if (connect) {
            ws.onmessage = (e) => {
                let reader = new FileReader();
                reader.onload = function () {
                    let data = reader.result;
                    setMessage(JSON.parse(data))
                };
                reader.readAsBinaryString(e.data);
            };
        }
    }, [ws]);

    const auth = useAuthUser()
    const signOut = useSignOut()

    const roleLays = [
        { role: 1, compon: <MainLayout signOut={signOut} /> },
        { role: 2, compon: <ProjectManagerLayout signOut={signOut} /> },
        { role: 3, compon: <ManufactoryLayout signOut={signOut} /> },
        { role: 4, compon: <Director signOut={signOut} /> },
        { role: 5, compon: <Hr signOut={signOut} /> },
        { role: 6, compon: <Buxgalter signOut={signOut} /> },
    ]

    useEffect(() => {
        onConnect()
    }, []);
    const isAuthenticated = useIsAuthenticated()

    if (isAuthenticated()) {
        return (
            <>
                <audio src={sound} ref={audio} />
                <ConfigProvider
                    theme={{
                        token: {
                            colorPrimary: '#2A74BA',
                            borderRadius: 2,
                            colorBgContainer: '#FFF',
                        },
                    }}
                >
                    <SocketProvider value={socmess}>
                        <Routes>
                            <Route path={'/'} element={<Home />} />
                            <Route path={'/login'} element={<LoginLayout />} />
                            <Route path={'/main/*'} element={
                                <RequireAuth loginPath={'/login'}>
                                    {roleLays.find(r => r.role === auth().role_id).compon}
                                </RequireAuth>
                            } />
                        </Routes>
                    </SocketProvider>
                </ConfigProvider>
            </>
        )
    } else {
        return (
            <LoginLayout />
        )
    }
};
export default App;
