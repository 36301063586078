import { UploadFile } from "../../../Components/Upload/UploadFile";
import { useEffect, useState } from "react";
import { Button, Form, Input, Space, Upload, message } from "antd";
import axios from "axios";
import { SaveOutlined, UploadOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { FileCard } from "../../../Components/FileCard/FileCard";
import { useForm } from "antd/es/form/Form";

export const TaskFiles = ({ task_id, render }) => {
    let [file, setFile] = useState('')
    let [files, setFiles] = useState([])
    let [filename, setFilename] = useState('')
    let [rend, setRend] = useState(false)
    let p = useParams()

    console.log(render)
    useEffect(() => {
        axios.get('taskfiles/' + p.id + '/' + task_id).then(res => {
            if (res.status === 200) {
                setFiles(res.data)
            }
        })
    }, [rend, render]);

    let [form] = useForm()

    const props = {
        name: 'image',
        action: process.env.REACT_APP_MAINURL + '/api/file',
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                // console.log(info.file);
                setFile(info.file.response.data.image)
                setFilename(info.file.name)
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    console.log(p)
    let onDelete = (id) => {
        axios.delete('taskfiles/' + id).then(res => {
            if (res.status === 200) {
                setRend(!rend)
            }
        })
    }

    return (
        <>
            <div style={{ overflow: 'auto', padding: '0 15px' }}>
                <h4>Файлы</h4>
                <Form
                    form={form}
                    onFinish={(values) => {
                        values.filename = filename
                        values.filepath = file
                        values.project_id = p.id
                        values.task_id = task_id
                        console.log(values)
                        axios.post('taskfiles', values).then(res => {
                            if (res.status === 200) {
                                setRend(!rend)
                                form.resetFields()
                            }
                        })
                    }}>
                    <Space.Compact
                        style={{
                            width: '100%',
                        }}
                    >
                        <Upload {...props}>
                            <Button type="primary" icon={<UploadOutlined />} style={{ backgroundColor: 'green' }}>Выбрать
                                файл</Button>
                        </Upload>
                        <Form.Item style={{ width: '100%' }} name={'comment'} rules={[
                            {
                                required: true,
                                message: 'Ввведите комментарий к файлу',
                            },
                        ]}>
                            <Input placeholder={"Введите описание..."} />
                        </Form.Item>
                        <Button type="primary" htmlType={'submit'} icon={<SaveOutlined />} />
                    </Space.Compact>
                </Form>

                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '15px', backgroundColor: '#f5f5f5', padding: '15px' }}>
                    {files.map(f => <FileCard key={f.id} file={f} onDelete={onDelete} />)}
                </div>
            </div>
        </>
    )
}