import {
    Button,
    Col,
    Form,
    Input,
    message,
    Row,
    Select,
    Upload
} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";
import axios from "axios";
import {useEmployee} from "./EmployeeState";

export function AddEmployee({adder, dt}) {
    let {setEmployees} = useEmployee()
    const [data, setData] = useState({});
    const [userImg, setUserImg] = useState(process.env.REACT_APP_MAINURL + '/upload/user/defuser.png');
    let [form] = Form.useForm()
    useEffect(() => {
        axios.get('/regdata').then(datar => {
            setData(datar.data)
        })
    }, []);


    const props = {
        name: 'image',
        action: process.env.REACT_APP_URL + '/file',
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file.response, info.fileList);
                setUserImg(process.env.REACT_APP_MAINURL + info.file.response.data.image)
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };


    const onFinish = (values) => {
        values.img = userImg
        axios.post('/employees', values).then(resData => {
            if (resData.status === 200) {
                setEmployees()
            }
        })

    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            <Form
                form={form}
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <fieldset>
                    <legend>Основная информация</legend>
                    <Row gutter={15}>
                        <Col span={12}>
                            <Form.Item label="Фамилия" name="lastname"
                                       rules={[
                                           {
                                               required: true,
                                               message: 'Заполните поле!',
                                           },
                                       ]}>
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Имя" name="firstname"
                                       rules={[
                                           {
                                               required: true,
                                               message: 'Заполните поле!',
                                           },
                                       ]}>
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Отчество" name="surname">
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="ПИНФЛ" name="jshshir">
                                <Input placeholder={'305......'}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item label="Загрузить изображению" name={'user_img'} valuePropName="user_img">
                        <Upload {...props}>
                            <Button icon={<UploadOutlined/>}>Выбрать</Button>
                        </Upload>
                    </Form.Item>
                </fieldset>

                <fieldset>
                    <legend>Контакты</legend>

                    <Row gutter={15}>
                        <Col span={8}>
                            <Form.Item label="Телефон" name="phone">
                                <Input type='phone' placeholder={'+998---------'}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Область" name='viloyat_id'>
                                <Select>
                                    {data.regions?.map(item => <Select.Option key={item.id}
                                                                              value={item.id}>{item.nameru}</Select.Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item label="Аддресс" name='address'>
                        <Input/>
                    </Form.Item>
                </fieldset>

                <fieldset>
                    <legend>Данные для доступа систему</legend>

                    <Row gutter={15}>
                        <Col span={12}>
                            <Form.Item label="Логин" name="username">
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Email" name="email">
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Пароль" name="password">
                                <Input.Password/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Рол или дольжность" name="role_id">
                                <Select showSearch>
                                    {data.roles?.map(item => <Select.Option key={item.id}
                                                                            value={item.id}>{item.role}</Select.Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                </fieldset>


                <Form.Item>
                    <Button type={'primary'} htmlType="submit">Сохранить</Button>
                </Form.Item>
            </Form>
        </>
    );

}