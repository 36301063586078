import {useEffect, useState} from "react";
import axios from "axios";
import {Button} from "antd";
import {Drawer, Table} from "antd/lib";
import {
    ArrowUpOutlined,
    ArrowDownOutlined,
    ArrowsAltOutlined,
    DollarCircleOutlined
} from '@ant-design/icons';

export function Valkurs() {
    let [data, setData] = useState([])
    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        axios.get('/valkurs').then(data => {
            setData(data.data)
        })
    }, []);
    let col = [
        {
            title: <ArrowsAltOutlined />,
            dataIndex: 'Diff',
            key: '',
            render: (_, item)=> (item.Diff > 0) ? <ArrowUpOutlined style={{color: 'blue'}} /> : <ArrowDownOutlined style={{color: 'red'}} />
        },
        {
            title: 'Валюта',
            dataIndex: 'CcyNm_UZ',
            key: 'CcyNm_UZ',
        },
        {
            title: 'Курс',
            dataIndex: 'Rate',
            key: 'Rate',
        },
        {
            title: 'Изменение',
            dataIndex: 'Diff',
            key: 'Diff',
        },
    ]
    return (
        <>
            <Button size={'small'} type="primary" shape={'circle'} icon={<DollarCircleOutlined />} onClick={showDrawer} />
            <Drawer size={'large'} title="Курсы валют" placement="right" onClose={onClose} open={open}>
               <Table pagination={false} rowKey={'id'} columns={col} dataSource={data} />
            </Drawer>
        </>
    )
}