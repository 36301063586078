import { Button, Form, Input } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useEffect } from 'react';
import { useSalary } from './SalaryStore';

function CheckSalary({ current }) {
    let { writeSalary } = useSalary()
    let [form] = useForm()
    useEffect(() => {
        form.setFieldsValue(current)
    }, [current]);

    return (
        <>
            <h4>Сотрудник: {current.firstname} {current.lastname}</h4>
            <h4>На балансе сотрудника: <span style={{ color: 'red' }}>{current.balance}</span> сум</h4>
            <h4>Налог из дохода: <span style={{ color: 'red' }}>12</span>%</h4>
            <Form name="basic"
                form={form}
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
                onFinish={writeSalary}
            >
                <Form.Item
                    label="ID"
                    name="id"
                    hidden
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Зарплата"
                    name="salary"
                >
                    <Input type='number' />
                </Form.Item>
                <Form.Item
                    label="Премия"
                    name="bonus"
                >
                    <Input type='number' />
                </Form.Item>
                <Form.Item
                    label="Штрафы"
                    name="fine"
                >
                    <Input type='number' />
                </Form.Item>
                <Form.Item
                    label="Налоги"
                    name="vat"
                >
                    <Input type='number' />
                </Form.Item>
                <Form.Item
                    label="Оплачено"
                    name="payed"
                >
                    <Input />
                </Form.Item>
                <Form.Item><Button type='primary' htmlType='submit'>Записать</Button></Form.Item>
            </Form>
        </>
    );
}

export default CheckSalary;