import {Avatar, Button, Form, Input, List, message, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";
import css from './Discussion.module.css'
import axios from "axios";
import {useParams} from "react-router-dom";

export function PDiscussion() {
    const [pImg, setPImg] = useState('');
    let [rend, setRend] = useState(true)
    let [mess, setMess] = useState([])
    let params = useParams();
    useEffect(()=>{
        axios.get('/discus/' + params.id).then(json=>setMess(json.data))
    }, [rend])


    const props = {
        name: 'image',
        action: process.env.REACT_APP_URL + '/file',
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                setPImg(process.env.REACT_APP_MAINURL + info.file.response.data.image)
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };


    const onFinish = (values) => {
        values.p_id = params.id
        values.file = pImg
        axios.post('/discus', values).then(resData => {
            let result = resData.data
            console.log(result)
            setRend(!rend)
        })
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            <div className={css.discblock}>
                <div className={css.messages}>
                    <List
                        itemLayout="horizontal"
                        dataSource={mess}
                        renderItem={(item, index) => (
                            <List.Item actions={(item.file) ? [<a href={item.file} target={'_blank'} download>Загрузить файл</a>] : []}>
                                <List.Item.Meta
                                    avatar={<Avatar src={item.img} />}
                                    title={<>{item.lastnem} {item.firstname}</>}
                                    description={item.message}
                                />
                            </List.Item>
                        )}
                    />
                </div>
                <Form
                    className={css.form}
                    labelCol={{
                        span: 24,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >

                    <Form.Item style={{marginBottom: '0'}} name="message">
                        <Input size={'large'} addonBefore={
                            <Form.Item name={'fileSend'} style={{marginBottom: '0'}} valuePropName="user_img">
                                <Upload {...props}>
                                    <Button type={'text'} icon={<UploadOutlined/>}>Загрузить файл</Button>
                                </Upload>
                            </Form.Item>
                        } addonAfter={
                            <Form.Item style={{marginBottom: '0'}}>
                                <Button type={"text"} size={'middle'} htmlType="submit">Отправить</Button>
                            </Form.Item>
                        } placeholder={'Izohni kiriting'}/>
                    </Form.Item>

                </Form>

            </div>
        </>
    )
}