import React, {useCallback, useState} from "react";

const SocContext = React.createContext(null);

export function SocketProvider({ children, ...props }) {
    const context = useCreateAppContext(props);
    return <SocContext.Provider value={context}>{children}</SocContext.Provider>;
}
export const useCreateAppContext = function(props) {
    const [test, setTest] = useState(props || 'No data');

    const toggleTest = useCallback(() => {
        setTest(_test => (_test === 'Hi' ? 'You are awesome' : 'Hi'));
    });

    return {
        test,
        toggleTest,
    };
}

export function useSocContext() {
    const context = React.useContext(SocContext);
    if (!context) throw new Error('Use app context within provider!');
    return context;
}