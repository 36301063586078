import { Button, Space, Tabs } from "antd";
import {
    EyeOutlined,
    GroupOutlined,
    ScheduleOutlined,
    CheckSquareOutlined,
    BoxPlotOutlined,
    MessageOutlined,
    LeftOutlined
} from "@ant-design/icons";
import { Pvexa } from "./Pvexa/Pvexa";
import { PTasks } from "./PTasks/PTasks";
import { PTaskBoard } from "./PTaskBoard/PTaskBoard";
import { PDiscussion } from "./Discussion/PDiscussion";
import { TaskGant } from "./PTasks/TaskGant";
import { PDash } from "./PDash/PDash";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { PFiles } from "./PFiles/PFiles";
import { StockFiles } from "./PFiles/StockFiles";
import Executes from "./Executes/Executes";

export function SProj() {

    let [prj, setPrj] = useState({})
    let params = useParams();
    useEffect(() => {
        axios.get('project/' + params.id).then(data => {
            setPrj(data.data)
        })
    }, [])

    // const onChange = (key) => {
    //     console.log(key);
    // };
    const items = [
        {
            key: '1',
            label: <span><EyeOutlined /> Обзор</span>,
            children: <PDash />,
        },
        {
            key: '2',
            label: <span><EyeOutlined /> Исходные файлы</span>,
            children: <StockFiles />,
        },
        {
            key: '3',
            label: <span><GroupOutlined /> Группа задач</span>,
            children: <Pvexa />,
        },
        {
            key: '4',
            label: <span><CheckSquareOutlined /> Задачы</span>,
            children: <PTasks />,
        },
        {
            key: '5',
            label: <span><ScheduleOutlined /> Доска Задач</span>,
            children: <PTaskBoard />,
        },
        {
            key: '6',
            label: <span><BoxPlotOutlined /> Диаграмма Ганта</span>,
            children: <TaskGant />,
        },
        {
            key: '7',
            label: <span><BoxPlotOutlined /> Файлы проекта</span>,
            children: <PFiles />,
        },

        {
            key: '8',
            label: <span><MessageOutlined /> Обсуждение</span>,
            children: <PDiscussion />,
        },
        {
            key: '9',
            label: <span><MessageOutlined /> Исполнители</span>,
            children: <Executes />,
        },
    ];
    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Link to={'/main/services'}>
                    <Button style={{ backgroundColor: 'green' }} type={'primary'} icon={<LeftOutlined />} />
                </Link>
                <h3>Деталы проекта: {prj?.project_name ?? ''}</h3>
                <Space>
                    <Button>Test</Button>
                </Space>
            </div>
            <Tabs type={'card'} defaultActiveKey="5" items={items} />
        </>
    )
}